import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Import Containers
import { DefaultLayoutComponent } from "./containers";

import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { LoginComponent } from "./views/login/login.component";
import { RegisterComponent } from "./views/register/register.component";
import { ListAccountsComponent } from "./views/list-accounts/list-accounts.component";
import { StaffAccountsComponent } from "./views/staff-accounts/staff-accounts.component";
import { ListDeveloperComponent } from "./views/list-developer/list-developer.component";
import { AccessLabelComponent } from "./views/access-label/access-label.component";
import { AppAccountComponent } from "./views/app-account/app-account.component";
import { DeveloperAccountsComponent } from "./views/developer-accounts/developer-accounts.component";
import { StatisticsComponent } from "./views/statistics/statistics.component";
import { ListStaffDeveloperComponent } from "./views/list-staff-developer/list-staff-developer.component";
import { AppSpecificStatisticsComponent } from "./views/app-specific-statistics/app-specific-statistics.component";
import { SystemWideStatisticsComponent } from "./views/system-wide-statistics/system-wide-statistics.component";
import { ChangePasswordComponent } from "./views/change-password/change-password.component";
import { ChangeLecturerPasswordComponent } from "./views/change-lecturer-password/change-lecturer-password.component";
import { ChangeDeveloperPasswordComponent } from "./views/change-developer-password/change-developer-password.component";
import { ChangeStaffPasswordComponent } from "./views/change-staff-password/change-staff-password.component";
import { ListAppComponent } from "./views/list-app/list-app.component";
import { ViewAppComponent } from "./views/view-app/view-app.component";
import { ListEmailsComponent } from "./views/list-emails/list-emails.component";
import { EditEmailsComponent } from "./views/edit-emails/edit-emails.component";
import { EditEmailsDeveloperComponent } from "./views/edit-emails-developer/edit-emails-developer.component";
import { EditEmailsStaffComponent } from "./views/edit-emails-staff/edit-emails-staff.component";
import { EditListComponent } from "./views/edit-list/edit-list.component";
import { EditAppAccountComponent } from "./views/edit-app-account/edit-app-account.component";
import { EditDeveloperListComponent } from "./views/edit-developer-list/edit-developer-list.component";
import { EditStaffAccountComponent } from "./views/edit-staff-account/edit-staff-account.component";
import { EditAccessLabelComponent } from "./views/edit-access-label/edit-access-label.component";
import { BuilderComponent } from "./views/builder/builder.component";
import { AccMagaComponent } from "./views/acc-maga/acc-maga.component";
import { ManageCategoriesComponent } from './views/admin/manage-categories/manage-categories.component';
import { AppCustomizationComponent } from './views/app-customization/app-customization.component';

export const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full"
  },
  {
    path: "404",
    component: P404Component,
    data: {
      title: "Page 404"
    }
  },
  {
    path: "500",
    component: P500Component,
    data: {
      title: "Page 500"
    }
  },
  {
    path: "login",
    component: LoginComponent,
    data: {
      title: "Login Page"
    }
  },
  {
    path: "register",
    component: RegisterComponent,
    data: {
      title: "Register Page"
    }
  },
  {
    path: "",
    component: DefaultLayoutComponent,
    data: {
      title: "Home"
    },
    children: [
      {
        path: "base",
        loadChildren: () =>
          import("./views/base/base.module").then(m => m.BaseModule)
      },
      {
        path: "buttons",
        loadChildren: () =>
          import("./views/buttons/buttons.module").then(m => m.ButtonsModule)
      },
      {
        path: "charts",
        loadChildren: () =>
          import("./views/chartjs/chartjs.module").then(m => m.ChartJSModule)
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./views/dashboard/dashboard.module").then(
            m => m.DashboardModule
          )
      },
      {
        path: "icons",
        loadChildren: () =>
          import("./views/icons/icons.module").then(m => m.IconsModule)
      },
      {
        path: "notifications",
        loadChildren: () =>
          import("./views/notifications/notifications.module").then(
            m => m.NotificationsModule
          )
      },
      {
        path: "theme",
        loadChildren: () =>
          import("./views/theme/theme.module").then(m => m.ThemeModule)
      },
      {
        path: "lecturer_accounts",
        component: BuilderComponent
      },
      {
        path: "listaccounts",
        component: ListAccountsComponent
      },
      {
        path: "staffaccount",
        component: StaffAccountsComponent
      },
      {
        path: "listdeveloper",
        component: ListDeveloperComponent
      },
      {
        path: "listemails",
        component: ListEmailsComponent
      },
      {
        path: "editemails",
        component: EditEmailsComponent
      },
      {
        path: "editemailsdeveloper",
        component: EditEmailsDeveloperComponent
      },
      {
        path: "editemailsstaff",
        component: EditEmailsStaffComponent
      },
      {
        path: "access-label",
        component: AccessLabelComponent
      },
      {
        path: "appaccounts",
        component: AppAccountComponent
      },
      {
        path: "appcustomization",
        component: AppCustomizationComponent
      },
      {
        path: "developeraccounts",
        component: DeveloperAccountsComponent
      },
      {
        path: "statistics",
        component: StatisticsComponent
      },
      {
        path: "staffdeveloper",
        component: ListStaffDeveloperComponent
      },
      {
        path: "specificstatistics",
        component: AppSpecificStatisticsComponent
      },
      {
        path: "systemtatistics",
        component: SystemWideStatisticsComponent
      },
      {
        path: "changepassword",
        component: ChangePasswordComponent
      },
      {
        path: "changelecturerpassword",
        component: ChangeLecturerPasswordComponent
      },
      {
        path: "changedeveloperpassword",
        component: ChangeDeveloperPasswordComponent
      },
      {
        path: "changestaffpassword",
        component: ChangeStaffPasswordComponent
      },
      {
        path: "listapps",
        component: ListAppComponent
      },
      {
        path: "viewapp",
        component: ViewAppComponent
      },
      {
        path: 'managecategories', component: ManageCategoriesComponent
      },
      {
        path: "editlist",
        component: EditListComponent
      },
      {
        path: "editappaccount",
        component: EditAppAccountComponent
      },
      {
        path: "editdeveloper",
        component: EditDeveloperListComponent
      },
      {
        path: "staffedit",
        component: EditStaffAccountComponent
      },
      {
        path: "accmaga",
        component: AccMagaComponent
      },
      {
        path: "accesslabel",
        component: AccessLabelComponent
      },
      {
        path: "editaccesslabel",
        component: EditAccessLabelComponent
      },

      {
        path: "widgets",
        loadChildren: () =>
          import("./views/widgets/widgets.module").then(m => m.WidgetsModule)
      }
    ]
  },
  { path: "**", component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
