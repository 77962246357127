import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-app-specific-statistics',
  templateUrl: './app-specific-statistics.component.html',
  styleUrls: ['./app-specific-statistics.component.scss']
})
export class AppSpecificStatisticsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
