import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { retry, catchError } from "rxjs/operators";
import { StorageService } from "./storage.service";
import { Observable, throwError } from "rxjs";

@Injectable({
  providedIn: "root"
})

export class ApiService {
  private REST_API_SERVER = "https://api.shiur.cloud:3000/";
  // private REST_API_SERVER = "http://localhost:3000/";
  constructor(
    private routes: Router,
    private httpClient: HttpClient,
    private store: StorageService
  ) {}

  popover(msg) {
    alert(msg);
  }

  login(data) {
    return this.httpClient
      .post(this.REST_API_SERVER + "user/login", data)
      .pipe(catchError(this.handleError));
  }

  logout() {
    this.store.remove_token();
    this.routes.navigate(["/login"]);
  }

  register() {}

  uploadjson(jsonfile: File, url, path): Observable<any> {
    var formData: any = new FormData();
    formData.append("name", name);
    formData.append("json", jsonfile);
    formData.append("path", path);

    return this.httpClient
      .post(this.REST_API_SERVER + url, formData, {
        headers: { 
          'path': path
        }})
      .pipe(catchError(this.errorMgmt));
  }

  uploadimage(name: string, profileImage: File, url, appid): Observable<any> {
    var formData: any = new FormData();
    formData.append("appid", appid);
    formData.append("name", name);
    formData.append("image", profileImage);

    return this.httpClient
      .post(this.REST_API_SERVER + url, formData, {
        reportProgress: true,
        observe: "events"
      })
      .pipe(catchError(this.errorMgmt));
  }


  uploadimage1(name: string, profileImage, url, appid): Observable<any> {
    var formData: any = new FormData();
    formData.append("appid", appid);
    formData.append("name", name);
    formData.append("image", profileImage);

    return this.httpClient
      .post(this.REST_API_SERVER + url, formData, {
        reportProgress: true,
        observe: "events"
      })
      .pipe(catchError(this.errorMgmt));
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  /************Post***********/
  postmethod(url, data) {
    return this.httpClient
      .post(this.REST_API_SERVER + url, data)
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = "Unknown error!";
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  handle_Error(res) {
    this.popover(res.message);
    if (res.code == 400) {
      // Server query error
    } else if (res.code == 401) {
      // Session Expired
      this.logout();
    } else if (res.code == 402) {
      // Something required
    } else if (res.code == 409) {
      // Conflict (Already Registered)
    }
  }

  // Pages Length
  getpageslength(data) {
    if (data > 0 && data < 11) {
      return [10];
    } else if (data > 10 && data < 26) {
      return [10, 25];
    } else if (data > 25 && data < 51) {
      return [10, 25, 50];
    } else if (data > 50 && data < 101) {
      return [10, 25, 50, 100];
    } else if (data > 100 && data < 151) {
      return [10, 25, 50, 100, 150];
    }
  }

  getpagelength(data) {
    if (data > 0 && data < 11) {
      return [1];
    } else if (data > 10 && data < 21) {
      return [1, 2];
    } else if (data > 20 && data < 31) {
      return [1, 2, 3];
    } else if (data > 30 && data < 41) {
      return [1, 2, 3, 4];
    } else if (data > 40 && data < 51) {
      return [1, 2, 3, 4, 5];
    } else if (data > 50 && data < 61) {
      return [1, 2, 3, 4, 5];
    } else if (data > 60 && data < 71) {
      return [1, 2, 3, 4, 5, 6];
    } else if (data > 70 && data < 81) {
      return [1, 2, 3, 4, 5, 6, 7];
    } else if (data > 80 && data < 91) {
      return [1, 2, 3, 4, 5, 6, 7, 8];
    }
  }

  getlength(no, mu){
    return [(no-1)*mu, no*mu]
  }
}
