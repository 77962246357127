import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";
import { MatDialog } from "@angular/material/dialog";
import {
  ConfirmDialogModel,
  ConfirmDialogComponent
} from "../../confirm-dialog/confirm-dialog.component";
import * as $ from "jquery";

@Component({
  selector: "kt-list-staff-developer",
  templateUrl: "./list-staff-developer.component.html",
  styleUrls: ["./list-staff-developer.component.scss"]
})
export class ListStaffDeveloperComponent implements OnInit {
  searchText: any;
  constructor(
    private router: Router,
    private api: ApiService,
    private store: StorageService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.stafflist();
    $(document).ready(function() {
      $('.selectall').click(function() {
        if ($(this).is(':checked')) {
            $('div input').attr('checked', true);
        } else {
            $('div input').attr('checked', false);
        }
    });
    });
  }
  bulk_action_data: any = "";
  bulkaction(){
    if(!this.bulk_action_data){
      return alert("Please select action")
    }
    var selectedbulk = []
    var selectedbulkdata = []
    $('input[class="bulk-action"]:checked').each(function() {
      selectedbulk.push(this.value)
   });
   if(selectedbulk && selectedbulk.length){
    selectedbulk.forEach((data, index)=>{
      if(this.bulk_action_data == 'active'){
        this.staffs[parseInt(data)].staff_status.BOOL = true
      }
      else if(this.bulk_action_data == 'inactive'){
        this.staffs[parseInt(data)].staff_status.BOOL = false
      }
      else if(this.bulk_action_data == 'delete'){
        this.staffs[parseInt(data)].enablestatus.BOOL = false
      }
      selectedbulkdata.push(this.staffs[parseInt(data)])
      if(index == selectedbulk.length-1){
        this.bulk_action(selectedbulkdata)
      }
    })
   }
   else{
    alert("Please select lecturers from list")
   }
  }  
  selected :any;
  select(item) {
    console.log(item)
    this.selected = item; 
};
isActive(item) {
  return this.selected === item;
};
goselected :any;
goisActive(item) {
  return this.goselected === item;
};
  bulk_action(list){
    this.goselected = 'go'; 
    try {
      this.api
        .postmethod("user/bulkaction/staff", { sessionid: this.store.get_token(), list: list, action: this.bulk_action_data })
        .subscribe((res: any) => {
          this.goselected = ''; 
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            setTimeout(()=>{
              this.stafflist()
            }, 2000)
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.goselected = ''; 
      this.api.handle_Error(err);
    }
  }
  staffs: any = [];
  staffs_list: any = [];
  pagenumbers: any = ''
  pagenumber: any = ''
  stafflist() {
    try {
      this.api
        .postmethod("user/all/staff", {
          sessionid: this.store.get_token()
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.staffs = []
            this.staffs_list = res.data.Items
            // this.listqty = 10
            // this.selected = 1
            // this.staffs_list.forEach((data, index)=>{
            //   if(index<10){
            //     this.staffs.push(data)
            //   }
            //  })
            //  this.pagenumbers = '';
            //  this.pagenumber = '';
            //  this.pagenumbers = this.api.getpageslength(
            //    this.staffs_list.length
            //  );
            //  this.pagenumber = this.api.getpagelength(
            //    this.staffs_list.length
            //  );
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }

  accessLabel() {
    this.router.navigate(["/accesslabel"]);
  }
  appView() {
    this.router.navigate(["/accmaga"]);
  }
  addNewStaffDeveloper() {
    this.router.navigate(["/staffaccount"]);
  }
  goBack() {
    window.history.back();
  }

  gotopage(pagenumber){
    // return
    this.selected = pagenumber
    if(this.staffs_list.length>this.listqty){
      this.staffs = []
      this.staffs_list.forEach((data, index)=>{
        if(index>=this.api.getlength(pagenumber, this.listqty)[0] && index<this.api.getlength(pagenumber, this.listqty)[1]){
          this.staffs.push(data)
        }
       })
    }
  }
  sendStaffDeveloperEmail(data)
  {
    // confirm("Send Email to Staff Developer?");
    try {
      this.api
        .postmethod("user/register/signupemail", {
          sessionid: this.store.get_token(),
          email: data.email.S,
          developerpush: data.developerpush.BOOL,
          template_type: 'staff',
          template_name: 'createstaff',
          enable_status: data.enablestatus.BOOL,
          firstname: data.firstname.S,
          lastname: data.lastname.S,
          password: data.password.S,
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }

  changeStaffPassword(data) {
    this.router.navigate(["/changestaffpassword"], {
      queryParams: {
        data: JSON.stringify(data)
      }
    });
  }

  editStaffList(data) {
    this.router.navigate(["staffedit"], {
      queryParams: {
        data: JSON.stringify(data)
      }
    });
  }
  deletestaff(staffid: any, staffcreate: any) {
    try {
      this.api
        .postmethod("user/delete/staff", {
          sessionid: this.store.get_token(),
          staffid: staffid,
          staffcreate: staffcreate
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            this.stafflist();
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }

  result: string = "";
  confirmDialog(data, data1): void {
    var message = `Are you sure you want to delete?`;

    var dialogData = new ConfirmDialogModel("", message);

    var dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;

      if (this.result) {
        this.deletestaff(data, data1);
      }
    });
  }

  listqty: any
  changeSelection(){
    this.staffs = []
    var tis = this
    this.staffs_list.forEach((data, index)=>{
      if(index<this.listqty){
        this.staffs.push(data)
      }
      if(index == this.staffs_list.length - 1){
        if(tis.staffs_list.length<=parseInt(tis.listqty)){
          tis.pagenumber = [1]
        }
        else{
          tis.pagenumber = tis.api.getpagelength(
            tis.staffs_list.length
          );
        }
      }
     })
  }
}
