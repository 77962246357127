import { Component } from "@angular/core";
import { navItems } from "../../_nav";
//import { navItems1 } from "../../_nav1";
import { ApiService } from "../../services/api.service";
import { Router } from "@angular/router";

//import { StorageService } from '../../services/storage.service';
@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html"
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems;
  today: number = Date.now();

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
  constructor(
    private api: ApiService,
    private routes: Router // private store: StorageService
  ) {
    // console.log(this.store.user_type())
    // if(this.store.user_type() == 'admin'){
    //   this.navItems = navItems1
    // }
    // else if(this.store.user_type() == 'superadmin'){
    //   this.navItems = navItems
    // }
    // else{
    this.navItems = navItems;
    // }
  }
  logout() {
    this.api.logout();
  }
  chngpass() {
    this.routes.navigate(["/changepassword"]);
  }
}
