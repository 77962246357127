import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";
import { HttpEvent, HttpEventType, HttpClient, HttpRequest} from "@angular/common/http";
import * as $ from 'jquery';

@Component({
  selector: 'app-view-app',
  templateUrl: './view-app.component.html',
  styleUrls: ['./view-app.component.css']
})
export class ViewAppComponent implements OnInit {
  submitted = false;
  accForm: FormGroup;
  submittedFC = false;
  custForm: FormGroup;
  desginControlForm: FormGroup;
  featureControlForm: FormGroup;

  submittedDesignControl = false;
  applogo: any
	design_control_background: any;
	design_control_upload: any;
	design_control_otp: any;
	design_control_internal: any;
  design_control_internal_pages_background: any;
  
  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private store: StorageService,
    private router: Router,
    private http: HttpClient,  
    private route: ActivatedRoute) { }

  sub: any
  ngOnInit(): void {
    var query: any = this.route.queryParams;
    this.sub = JSON.parse(query.value.data);
    if(this.sub.appid && this.sub.appid.S){
      this.getappdetail(this.sub.appid.S)
    }
    this.accForm = this.formBuilder.group({
      appname: ["", [Validators.required]],
      description: ["", [Validators.required]]
    });

		$('body').on('click','.nav-link', function(){
			if(localStorage.getItem('field_status')=='yes'){
				if($.trim($(this).text())=='BASICS'){
					setTimeout(() => this.custForm.disable(), 1000);
				}

				if($.trim($(this).text())=='DESIGN CONTROLS'){
					setTimeout(() => this.desginControlForm.disable(), 1000);
				}

				if($.trim($(this).text())=='FEATURES CONTROLS'){
					setTimeout(() => this.featureControlForm.disable(), 1000);
				}
			}
		});

		this.custForm = this.formBuilder.group({
	      appname: ["", [Validators.required, Validators.maxLength(22)]],
	      about_us: ["", [Validators.required]],
	      welcome: ["", [Validators.required]],
	      term_and_condition: ["", [Validators.required]],
	      contact_email: ["", [Validators.required]],
	      app_logo_file_choose: [""]
	    });

   		this.desginControlForm = this.formBuilder.group({
	      select_theme:["", [Validators.required]],
		  color_combination:["", [Validators.required]],    
		  color_combination_two:["", [Validators.required]],    
	      design_control_upload_splash: [""],
	      design_control_background_image:[""],
	      design_control_otp_screen:[""],
	      design_control_internal_pages:[""],
	      design_control_internal_pages_background_image:[""]
    	}); 

	    this.featureControlForm = this.formBuilder.group({
	      allow_comments:[null,""],
        restrict_content:[null,""],
        appstore:[""],
        playstore:[""]
      });
  }

  reassignvalues(){
    this.custForm.controls['appname'].setValue(this.singleapp.appname.S);
    this.custForm.controls['about_us'].setValue(this.singleapp.about_us.S);
    this.custForm.controls['welcome'].setValue(this.singleapp.welcome.S);
    this.custForm.controls['contact_email'].setValue(this.singleapp.contact_email.S);
    this.custForm.controls['term_and_condition'].setValue(this.singleapp.term_and_condition.S);
    this.applogo = this.singleapp.app_logo.S == null ? "" : this.singleapp.app_logo.S;

    this.desginControlForm.controls['select_theme'].setValue(this.singleapp.select_theme.S);
  this.desginControlForm.controls['color_combination'].setValue(this.singleapp.color_combination.S);
  this.desginControlForm.controls['color_combination_two'].setValue(this.singleapp.color_combination_two.S);
  this.design_control_background = this.singleapp.design_control_background_image.S
  this.design_control_upload = this.singleapp.design_control_upload_splash.S
  this.design_control_otp = this.singleapp.design_control_otp_screen.S
  this.design_control_internal = this.singleapp.design_control_internal_pages.S
  this.design_control_internal_pages_background = this.singleapp.design_control_internal_pages_background_image.S

  this.featureControlForm.controls['allow_comments'].setValue(this.singleapp.allow_comments.S);
  this.featureControlForm.controls['restrict_content'].setValue(this.singleapp.restrict_content.S);
  if(this.singleapp.appstore && this.singleapp.appstore.S && this.singleapp.appstore.S != "null"){
    this.featureControlForm.controls['appstore'].setValue(this.singleapp.appstore.S);
  }
  if(this.singleapp.playstore && this.singleapp.playstore.S && this.singleapp.playstore.S != "null"){
    this.featureControlForm.controls['playstore'].setValue(this.singleapp.playstore.S);
  }
}
  get f() {
    return this.accForm.controls;
  }

  singleapp: any;
getappdetail(Lusername){
  try {
    this.api
      .postmethod("user/singleapp/application", {
        sessionid: this.store.get_token(),
        Lusername: Lusername
      })
      .subscribe((res: any) => {
        if (res.status == "success" && res.code == 200 && res.data.Count>0) {
          this.singleapp = res.data.Items[0]
          this.accForm.controls['appname'].setValue(res.data.Items[0].appname.S);
          this.reassignvalues();
        } else {
          // this.api.handle_Error(res);
        }
      });
  } catch (err) {
    this.api.handle_Error(err);
  }
}
goBack() {
  window.history.back();
}
submitPreview(){
  
}

get designControl() {
  return this.desginControlForm.controls;
}


percentDone: number;
uploadSuccess: boolean;
size:any;
width:number;
height:number;
onChange(evt:any,maxwidth=300,maxheight=414){
  this.percentDone = 100;
  this.uploadSuccess = true;
  let image:any = evt.target.files[0];

  var filename = image.name;
  var extension = filename.replace(/^.*\./, '');

  if (extension == filename) {
    extension = '';
  } else {
    extension = extension.toLowerCase();
  }

if($.inArray(extension, ['jpg','png','jpeg']) == -1){
    alert('please valid upload image type supoorted');
    $(this).val('');
    return false;
}
  this.size = image.size;
  let fr = new FileReader();
  fr.onload = () => { // when file has loaded
  var img = new Image();

  var imgwidth = 0;
    var imgheight = 0;
    

  img.onload = () => {
      this.width = img.width;
      this.height = img.height;

      console.log(img.width)
      console.log(img.height)
      if(this.width <= maxwidth && this.height <= maxheight){
          console.log('its valid file');
      }else{
        evt.target.value = ''
        alert('Warning: image diemensions beyound required diemensions 300px X 415px');
      }
  };

  img.src = fr.result as string; // This is the data URL 
};

fr.readAsDataURL(image);
  // this.imgType.nativeElement.value = "";
}

firstTab(){
  var element = $('.tab').find('button.active');
  var element1 = $('.tab').find('button');
  var current_tab = $(element).attr('data-tab_id'); 
  $(element1).first().addClass('active');
  $(element).removeClass('active');
  $('#'+"BASIC").show();
  $('#'+current_tab).hide();
  $("html, body").animate({ scrollTop: 0 }, "slow");
}

nextTab(){
  var element = $('.tab').find('button.active');
  var current_tab = $(element).attr('data-tab_id'); 
  var next_tab = $(element).next().attr('data-tab_id');
  
  $(element).next().addClass('active');
  $(element).removeClass('active');
  $('#'+next_tab).show();
  $('#'+current_tab).hide();
  $("html, body").animate({ scrollTop: 0 }, "slow");
}

lastTab(){
  var element = $('.tab').find('button.active');
  var current_tab = $(element).attr('data-tab_id'); 
  var next_tab = $(element).prev().attr('data-tab_id');
  
  $(element).prev().addClass('active');
  $(element).removeClass('active');
  $('#'+next_tab).show();
  $('#'+current_tab).hide();
  $("html, body").animate({ scrollTop: 0 }, "slow");
}

resetForm(){
  $('form')[0].reset();
}
updateFeatureControl() {
  this.submittedFC = true;
  
  if (this.featureControlForm.invalid && this.submitted) {
    return;
  }

  this.submittedFC = false;

  this.submitFeatureControl();
}

submitFeatureControl(){
  // debugger
  var form_data = {
    sessionid:this.store.get_token(),
    allow_comments:this.featureControlForm.value.allow_comments,
    restrict_content:this.featureControlForm.value.restrict_content,
    lusername:localStorage.getItem('lusername'),
    xlusername:localStorage.getItem('xlusername')
  };

  try {
    this.api
      .postmethod("customize/update_feature_control", form_data)
      .subscribe((res: any) => {
        if (res.status == "success" && res.code == 200) {
          //this.api.popover(res.message);
          // this.featureControlForm.reset();
        } else {
          this.api.handle_Error(res);
        }
      });
  } catch (err) {
    this.api.handle_Error(err);
  }
 }


 desginControlsFormsubmitPreview() {
  this.submittedDesignControl = true;

  if (this.desginControlForm.invalid && this.submittedDesignControl) {
    return;
  }

  this.submittedDesignControl = false;

  var design_control_upload_splash: any = document.getElementById("design_control_upload_splash");
  var design_control_background_image: any = document.getElementById("design_control_background_image");
  var design_control_otp_screen: any = document.getElementById("design_control_otp_screen");
  var design_control_internal_pages: any = document.getElementById("design_control_internal_pages");
  var design_control_internal_pages_background_image: any = document.getElementById("design_control_internal_pages_background_image");


  if (design_control_upload_splash && design_control_upload_splash.value) {
    var files: any = document.getElementById("design_control_upload_splash");
    var file = files.files[0];
    if (file == null) {
      return alert("No file selected.");
    }
    this.api
      .uploadimage("image", file, "image-upload", "")
      .subscribe((event: HttpEvent<any>) => {
        console.log(event);
        var dd: any = event;
        if (dd && dd.body && dd.body.imageUrl) {
              design_control_upload_splash = dd.body.imageUrl;
              
              var form_data = {
                sessionid:this.store.get_token(),
                column_value:design_control_upload_splash,
                column_name:'design_control_upload_splash',
                lusername:localStorage.getItem('lusername'),
                xlusername:localStorage.getItem('xlusername')
              };

              try {
                this.api
                  .postmethod("customize/update_desgin_controls_images", form_data)
                  .subscribe((res: any) => {
                    console.log('done');
                  });
              } catch (err) {
                this.api.handle_Error(err);
              }
        }
      });
  }

  if (design_control_background_image && design_control_background_image.value) {
    var files: any = document.getElementById("design_control_background_image");
    var file = files.files[0];
    if (file == null) {
      return alert("No file selected.");
    }
    this.api
      .uploadimage("image", file, "image-upload", "")
      .subscribe((event: HttpEvent<any>) => {
        console.log(event);
        var dd: any = event;
        if (dd && dd.body && dd.body.imageUrl) {
          design_control_background_image = dd.body.imageUrl;

          var form_data = {
            sessionid:this.store.get_token(),
            column_value:design_control_background_image,
            column_name:'design_control_background_image',
            lusername:localStorage.getItem('lusername'),
            xlusername:localStorage.getItem('xlusername')
          };

          try {
            this.api
              .postmethod("customize/update_desgin_controls_images", form_data)
              .subscribe((res: any) => {
                console.log('done');
              });
          } catch (err) {
            this.api.handle_Error(err);
          }

        }
      });
  }

  if (design_control_otp_screen && design_control_otp_screen.value) {
    var files: any = document.getElementById("design_control_otp_screen");
    var file = files.files[0];
    if (file == null) {
      return alert("No file selected.");
    }
    this.api
      .uploadimage("image", file, "image-upload", "")
      .subscribe((event: HttpEvent<any>) => {
        console.log(event);
        var dd: any = event;
        if (dd && dd.body && dd.body.imageUrl) {
          design_control_otp_screen = dd.body.imageUrl;

          var form_data = {
            sessionid:this.store.get_token(),
            column_value:design_control_otp_screen,
            column_name:'design_control_otp_screen',
            lusername:localStorage.getItem('lusername'),
            xlusername:localStorage.getItem('xlusername')
          };

          try {
            this.api
              .postmethod("customize/update_desgin_controls_images", form_data)
              .subscribe((res: any) => {
                console.log('done');
              });
          } catch (err) {
            this.api.handle_Error(err);
          }

        }
      });
  }

  if (design_control_internal_pages && design_control_internal_pages.value) {
    var files: any = document.getElementById("design_control_internal_pages");
    var file = files.files[0];
    if (file == null) {
      return alert("No file selected.");
    }
    this.api
      .uploadimage("image", file, "image-upload", "")
      .subscribe((event: HttpEvent<any>) => {
        console.log(event);
        var dd: any = event;
        if (dd && dd.body && dd.body.imageUrl) {
          design_control_internal_pages = dd.body.imageUrl;

          var form_data = {
            sessionid:this.store.get_token(),
            column_value:design_control_internal_pages,
            column_name:'design_control_internal_pages',
            lusername:localStorage.getItem('lusername'),
            xlusername:localStorage.getItem('xlusername')
          };

          try {
            this.api
              .postmethod("customize/update_desgin_controls_images", form_data)
              .subscribe((res: any) => {
                console.log('done');
              });
          } catch (err) {
            this.api.handle_Error(err);
          }

        }
      });
  }

  if (design_control_internal_pages_background_image && design_control_internal_pages_background_image.value) {
    var files: any = document.getElementById("design_control_internal_pages_background_image");
    var file = files.files[0];
    if (file == null) {
      return alert("No file selected.");
    }
    this.api
      .uploadimage("image", file, "image-upload", "")
      .subscribe((event: HttpEvent<any>) => {
        console.log(event);
        var dd: any = event;
        if (dd && dd.body && dd.body.imageUrl) {
          design_control_internal_pages_background_image = dd.body.imageUrl;

          var form_data = {
            sessionid:this.store.get_token(),
            column_value:design_control_internal_pages_background_image,
            column_name:'design_control_internal_pages_background_image',
            lusername:localStorage.getItem('lusername'),
            xlusername:localStorage.getItem('xlusername')
          };

          try {
            this.api
              .postmethod("customize/update_desgin_controls_images", form_data)
              .subscribe((res: any) => {
                console.log('done');
              });
          } catch (err) {
            this.api.handle_Error(err);
          }


        }
      });
  }

  // debugger
  var form_data = {
    sessionid:this.store.get_token(),
    color_combination:this.desginControlForm.value.color_combination,
    color_combination_two:this.desginControlForm.value.color_combination_two,
    select_theme:this.desginControlForm.value.select_theme,
    lusername:localStorage.getItem('lusername'),
    xlusername:localStorage.getItem('xlusername')
  };

  try {
    this.api
      .postmethod("customize/desgin_controls", form_data)
      .subscribe((res: any) => {
        if (res.status == "success" && res.code == 200) {
          //this.api.popover(res.message);
          // this.featureControlForm.reset();
        } else {
          this.api.handle_Error(res);
        }
      });
  } catch (err) {
    this.api.handle_Error(err);
  }
}

openCity(evt, cityName) {
  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tabcontent");
  
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }
  

  document.getElementById(cityName).style.display = "block";
  evt.currentTarget.className += " active";
}


submitPreviewBasic() {
  this.submitted = true;
  
  if (this.custForm.invalid && this.submitted) {
    return;
  }

  this.submitted = false;

  var ss: any = document.getElementById("app_logo_file_choose");

  if (ss && ss.value) {
    var files: any = document.getElementById("app_logo_file_choose");
    var file = files.files[0];
    if (file == null) {
    return alert("No file selected.");
    }
    this.api
    .uploadimage("image", file, "image-upload", this.singleapp.Lusername.S)
    .subscribe((event: HttpEvent<any>) => {
      var dd: any = event;
      if (dd && dd.body && dd.body.imageUrl) {
      this.submitmediaBasic(dd.body.imageUrl);
      }
    });
  } else {
    this.submitmediaBasic(this.applogo);
  }
  }


	
	  submitmediaBasic(media="null"){
		var mm = "null";
		if(media){
		  mm = media
		}
	
		// debugger
		var form_data = {
		  sessionid:this.store.get_token(),
		  lusername:localStorage.getItem('lusername'),
		  xlusername:localStorage.getItem('xlusername'),
		  appname:this.custForm.value.appname,
		  about_us:this.custForm.value.about_us,
		  welcome:this.custForm.value.welcome,
		  term_and_condition:this.custForm.value.term_and_condition,
		  contact_email:this.custForm.value.contact_email,
		  app_logo_file_choose:mm
		};
	
		try {
		  this.api
			.postmethod("app_cust/update_app", form_data)
			.subscribe((res: any) => {
			  if (res.status == "success" && res.code == 200) {
				this.api.popover(res.message);
				setTimeout(()=>{
					this.featureControlForm.reset();
          if(this.sub.appid && this.sub.appid.S){
            this.getappdetail(this.sub.appid.S)
          }
				}, 2000)
			  } else {
				this.api.handle_Error(res);
			  }
			});
		} catch (err) {
		  this.api.handle_Error(err);
		}
	   }
}
