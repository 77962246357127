import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";
import { MatDialog } from "@angular/material/dialog";
import {
  ConfirmDialogModel,
  ConfirmDialogComponent
} from "../../confirm-dialog/confirm-dialog.component";
import * as $ from "jquery";

@Component({
  selector: "kt-list-accounts",
  templateUrl: "./list-accounts.component.html",
  styleUrls: ["./list-accounts.component.scss"]
})
export class ListAccountsComponent implements OnInit {
  searchText: any;
  constructor(
    private routes: Router,
    private api: ApiService,
    private store: StorageService,
    public dialog: MatDialog
  ) {
    this.appslist()
  }

  lecturer: any;
  ngOnInit() {
    $(document).ready(function() {
      $('.selectall').click(function() {
        if ($(this).is(':checked')) {
            $('div input').attr('checked', true);
        } else {
            $('div input').attr('checked', false);
        }
    });
    });
  }
  bulk_action_data: any = "";
  bulkaction(){
    var tis = this
    if(!this.bulk_action_data){
      return alert("Please select action")
    }
    var selectedbulk = []
    var selectedbulkdata = []
    $('input[class="bulk-action"]:checked').each(function() {
      selectedbulk.push(this.value)
   });
   if(selectedbulk && selectedbulk.length){
    selectedbulk.forEach((data, index)=>{
      if(tis.bulk_action_data == 'active'){
        tis.lecturers[parseInt(data)].enablestatus.BOOL = true
      }
      else if(tis.bulk_action_data == 'inactive'){
        tis.lecturers[parseInt(data)].enablestatus.BOOL = false
      }
      else if(tis.bulk_action_data == 'delete'){
        tis.lecturers[parseInt(data)].enablestatus.BOOL = false
      }
      selectedbulkdata.push(tis.lecturers[parseInt(data)])
      if(index == selectedbulk.length-1){
        tis.bulk_action(selectedbulkdata)
      }
    })
   }
   else{
    alert("Please select lecturers from list")
   }
  }
  goselected :any;
  goisActive(item) {
    return this.goselected === item;
  };
  bulk_action(list){
    this.goselected = 'go'; 
    try {
      this.api
        .postmethod("user/bulkaction/lecturer", { sessionid: this.store.get_token(), list: list, action: this.bulk_action_data })
        .subscribe((res: any) => {
          this.goselected = ''; 
          this.bulk_action_data = ""
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            setTimeout(()=>{
              this.lecturerList()
            }, 2000)
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.goselected = ''; 
      this.api.handle_Error(err);
    }
  }
  lecturers = [];
  lecturer_list = [];
  pagenumbers: any = ''
  pagenumber: any = ''
  lecturerList() {
    var tis = this
    try {
      this.api
        .postmethod("user/all/lecturer", { sessionid: this.store.get_token() })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            console.log('res',res);
            this.lecturer_list = []
            this.lecturers = res.data.Items
            tis.lecturers.forEach((data, index)=>{
              if(tis.apps_list.findIndex(e=>e.Lusername.S == data.appid.S)>-1){
              }
              else{
                tis.lecturers[index].appid.S = ""
              }
            })
            // this.listqty = 10
            // this.selected = 1
            // this.lecturers.forEach((data, index)=>{
            //   if(index<10){
            //     this.lecturer_list.push(data)
            //   }
            //  })
            //  this.pagenumbers = [];
            //  this.pagenumber = [];
            //  this.pagenumbers = this.api.getpageslength(
            //    this.lecturers.length
            //  );
            //  this.pagenumber = this.api.getpagelength(
            //    this.lecturers.length
            //  );
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  apps_list: any
  appslist() {
    try {
      this.api
        .postmethod("user/allapps/application", {
          sessionid: this.store.get_token()
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.apps_list = res.data.Items
            this.lecturerList();
          }else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  appView(data) {
    // this.routes.navigate(["/listapps"]);
    // this.routes.navigate(["/viewapp"], {
      console.log(data)
  try {
    this.api
      .postmethod("user/singleapp/application", {
        sessionid: this.store.get_token(),
        Lusername: data.appid.S
      })
      .subscribe((res: any) => {
        if (res.status == "success" && res.code == 200 && res.data.Count>0) {
          this.routes.navigate(["/editappaccount"], {
            queryParams: {
              data: JSON.stringify(res.data.Items[0])
            }
          });
        } else {
          // this.api.handle_Error(res);
        }
      });
  } catch (err) {
    this.api.handle_Error(err);
  }
  }
  addNewLecturer() {
    this.routes.navigate(["/lecturer_accounts"]);
  }
    changeLecturerPassword(data) {
    this.routes.navigate(["/changelecturerpassword"], {
      queryParams: {
        data: JSON.stringify(data)
      }
    });
  }

  goBack() {
    window.history.back();
  }
  gotopage(pagenumber){
    this.selected = pagenumber
    if(this.lecturers.length>this.listqty){
      this.lecturer_list = []
      this.lecturers.forEach((data, index)=>{
        if(index>=this.api.getlength(pagenumber, this.listqty)[0] && index<this.api.getlength(pagenumber, this.listqty)[1]){
          this.lecturer_list.push(data)
        }
       })
    }
  }
  sendLecturerEmail(data)
  {
    // confirm("Send Email to Lecturer?");
    try {
      this.api
        .postmethod("user/register/signupemail", {
          sessionid: this.store.get_token(),
          email: data.email.S,
          developerpush: data.notification.BOOL,
          template_type: 'lecturer',
          template_name: 'createlecturer',
          enable_status: data.lect_status.BOOL,
          firstname: data.firstname.S,
          lastname: data.lastname.S,
          password: data.password.S,
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }

  editList(data: any) {
    this.routes.navigate(["editlist"], {
      queryParams: {
        data: JSON.stringify(data)
      }
    });
  }
  deletelectrar(data: any, data1: any, data2: any) {
    try {
      this.api
        .postmethod("user/delete/lecturer", {
          sessionid: this.store.get_token(),
          lusername: data,
          xlusername: data1,
          appid: data2
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            this.lecturerList();
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  result: string = "";
  confirmDialog(data, data1, data2): void {
    var message = `Are you sure you want to delete?`;

    var dialogData = new ConfirmDialogModel("", message);

    var dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;

      if (this.result) {
        this.deletelectrar(data, data1, data2);
      }
    });
  }
  selected: any;
  isActive(item) {
    return this.selected === item;
};

  listqty: any
  changeSelection(){
    this.lecturer_list = []
    var tis = this
    this.lecturers.forEach((data, index)=>{
      if(index<this.listqty){
        this.lecturer_list.push(data)
      }
      if(index == this.lecturers.length - 1){
        if(tis.lecturers.length<=parseInt(tis.listqty)){
          tis.pagenumber = [1]
        }
        else{
          tis.pagenumber = tis.api.getpagelength(
            tis.lecturers.length
          );
        }
      }
     })
  }
  
}
