import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../../services/storage.service'
@Component({
  selector: 'app-manage-categories',
  templateUrl: './manage-categories.component.html',
  styleUrls: ['./manage-categories.component.css']
})
export class ManageCategoriesComponent implements OnInit {

  constructor(
    private store: StorageService
    ) { }

  ngOnInit(): void {
    console.log(this.store.user_type())
  }

}
