import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";

@Component({
  selector: "kt-edit-developer-list",
  templateUrl: "./edit-developer-list.component.html",
  styleUrls: ["./edit-developer-list.component.scss"]
})
export class EditDeveloperListComponent implements OnInit {
  sub: any;
  editlistForm: FormGroup;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private store: StorageService
  ) {}

  ngOnInit() {
    var query: any = this.route.queryParams;
    this.sub = JSON.parse(query.value.data);
    console.log(this.sub);
    this.editlistForm = this.formBuilder.group({
      username: [this.sub.username.S, [Validators.required]],
      email: [this.sub.email.S, [Validators.required]],
      companyname: [this.sub.companyname.S, [Validators.required]],
      firstname: [this.sub.firstname.S, [Validators.required]],
      lastname: [this.sub.lastname.S, [Validators.required]],
      enablestatus: [this.sub.dev_status.BOOL, [Validators.required]]
    });
  }

  get f() {
    return this.editlistForm.controls;
  }
  goBack() {
    window.history.back();
  }
  sendDeveloperEmail(data)
  {
    data = this.sub
    // confirm("Send Account Creation Email to Developer?");
    try {
      this.api
        .postmethod("user/register/signupemail", {
          sessionid: this.store.get_token(),
          email: data.email.S,
          developerpush: data.developerpush.BOOL,
          template_type: 'developer',
          template_name: 'createdeveloper',
          enable_status: data.enablestatus.BOOL,
          firstname: data.firstname.S,
          lastname: data.lastname.S,
          password: data.password.S,
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }

  selected :any;
  select(item) {
    console.log(item)
    this.selected = item; 
};
isActive(item) {
    return this.selected === item;
};
  model: any;
  onSubmit() {
    this.submitted = true;
    if (this.editlistForm.invalid) {
      return;
    }
    var status;
    if (
      this.editlistForm.value.enablestatus == "true" ||
      this.editlistForm.value.enablestatus == true
    ) {
      status = true;
    } else {
      status = false;
    }
//   debugger;
    this.selected = 'submit'
    try {
      this.api
        .postmethod("user/update/developer", {
          sessionid: this.store.get_token(),
          firstname: this.editlistForm.value.firstname,
          lastname: this.editlistForm.value.lastname,
          enablestatus: status,
          lusername: this.sub.devusername.S,
          xlusername: this.sub.appusername.S,
          email: this.sub.email.S,
          developerpush: this.sub.developerpush.BOOL,
        })
        .subscribe((res: any) => {
          this.selected = ''
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            this.router.navigate(["/listdeveloper"]);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.selected = ''
      this.api.handle_Error(err);
    }
  }

  resetPreview() {
    this.router.navigate(["/listdeveloper"]);
  }
}
