import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'kt-access-label',
  templateUrl: './access-label.component.html',
  styleUrls: ['./access-label.component.scss']
})
export class AccessLabelComponent implements OnInit {

  constructor(private router: Router) 
  { }

  ngOnInit() {
  }

  resetPreview() {
    this.router.navigate(["/staffdeveloper"]);
  }

  goBack() {
    window.history.back();
  }

}
