import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";

@Component({
  selector: "kt-developer-accounts",
  templateUrl: "./developer-accounts.component.html",
  styleUrls: ["./developer-accounts.component.scss"]
})
export class DeveloperAccountsComponent implements OnInit {
  submitted = false;
  developerForm: FormGroup;
  constructor(
    private api: ApiService,
    private store: StorageService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.developerForm = this.formBuilder.group({
      companyname: ["", [Validators.required]],
      username: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6)]],
      cpassword: ["", [Validators.required, Validators.minLength(6)]],
      firstname: ["", [Validators.required]],
      lastname: ["", [Validators.required]],
      notification: ['true', [Validators.required]],
      enablestatus: ["true", [Validators.required]]
    },{validator: this.checkIfMatchingPasswords('password', 'cpassword')});
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
          passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({notEquivalent: true})
      }
      else {
          return passwordConfirmationInput.setErrors(null);
      }
    }
  }
  ngOnInit() {}

  get f() {
    return this.developerForm.controls;
  }

  resetPreview() {
    this.router.navigate(["/listdeveloper"]);
  }
  goBack() {
    window.history.back();
  }

  selected :any;
  select(item) {
    console.log(item)
    this.selected = item; 
};
isActive(item) {
    return this.selected === item;
};
  submitPreview() {
    this.submitted = true;
    if (this.developerForm.invalid) {
      return;
    }
    if (
      this.developerForm.value.password != this.developerForm.value.cpassword
    ) {
      return alert("Password and confirm password should be same");
    }
    var notification;
    if (this.developerForm.value.notification == "true") {
      notification = true;
    } else {
      notification = false;
    }
    var enablestatus;
    if (this.developerForm.value.enablestatus == "true") {
      enablestatus = true;
    } else {
      enablestatus = false;
    }
    this.selected = 'submit'
    try {
      this.api
        .postmethod("user/create/developer", {
          companyname: this.developerForm.value.companyname,
          username: this.developerForm.value.username,
          firstname: this.developerForm.value.firstname,
          lastname: this.developerForm.value.lastname,
          developerpush: notification,
          status: enablestatus,
          password: this.developerForm.value.password,
          email: this.developerForm.value.email,
          timestamp: Date.now(),
          created: Date.now(),
          sessionid: this.store.get_token()
        })
        .subscribe((res: any) => {
          this.selected = ''
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            this.router.navigate(["/listdeveloper"]);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.selected = ''
      this.api.handle_Error(err);
    }
  }
}
