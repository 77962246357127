import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
import { AppComponent } from "./app.component";
// Import containers
import { DefaultLayoutComponent } from "./containers";
import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { LoginComponent } from "./views/login/login.component";
import { RegisterComponent } from "./views/register/register.component";
const APP_CONTAINERS = [DefaultLayoutComponent];
import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule
} from "@coreui/angular";
// Import routing module
import { AppRoutingModule } from "./app.routing";
// Import 3rd party components
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TabsModule } from "ngx-bootstrap/tabs";
import { ChartsModule } from "ng2-charts";
import { ListAccountsComponent } from "./views/list-accounts/list-accounts.component";
// import { LecturerAccountComponent } from './views/lecturer-account/lecturer-account.component';
import { AppAccountComponent } from "./views/app-account/app-account.component";
import { AppSpecificStatisticsComponent } from "./views/app-specific-statistics/app-specific-statistics.component";
import { ChangePasswordComponent } from "./views/change-password/change-password.component";
import { DeveloperAccountsComponent } from "./views/developer-accounts/developer-accounts.component";
import { EditAppAccountComponent } from "./views/edit-app-account/edit-app-account.component";
import { EditDeveloperListComponent } from "./views/edit-developer-list/edit-developer-list.component";
import { EditListComponent } from "./views/edit-list/edit-list.component";
import { EditStaffAccountComponent } from "./views/edit-staff-account/edit-staff-account.component";
import { ErrorPageComponent } from "./views/error-page/error-page.component";
import { ListAppComponent } from "./views/list-app/list-app.component";
import { ListDeveloperComponent } from "./views/list-developer/list-developer.component";
import { ListStaffDeveloperComponent } from "./views/list-staff-developer/list-staff-developer.component";
import { StaffAccountsComponent } from "./views/staff-accounts/staff-accounts.component";
import { StatisticsComponent } from "./views/statistics/statistics.component";
import { SystemWideStatisticsComponent } from "./views/system-wide-statistics/system-wide-statistics.component";
import { AccessLabelComponent } from "./views/access-label/access-label.component";
import { BuilderComponent } from "./views/builder/builder.component";
import { NgbTabsetModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule } from "@angular/common/http";
import { AccMagaComponent } from "./views/acc-maga/acc-maga.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { ManageCategoriesComponent } from "./views/admin/manage-categories/manage-categories.component";
import { EditAccessLabelComponent } from './views/edit-access-label/edit-access-label.component';
import { ChangeLecturerPasswordComponent } from './views/change-lecturer-password/change-lecturer-password.component';
import { ChangeDeveloperPasswordComponent } from './views/change-developer-password/change-developer-password.component';
import { ChangeStaffPasswordComponent } from './views/change-staff-password/change-staff-password.component';
import { ListEmailsComponent } from './views/list-emails/list-emails.component';
import { EditEmailsComponent } from './views/edit-emails/edit-emails.component';
import { EditEmailsDeveloperComponent } from './views/edit-emails-developer/edit-emails-developer.component';
import { EditEmailsStaffComponent } from './views/edit-emails-staff/edit-emails-staff.component';
import { LoaderComponent } from "./services/loader.component";
import { LoaderService } from "./services/loader.service";
import { FilternamePipe } from './pipes/filtername.pipe';
import { FilterappsPipe } from './pipes/filterapps.pipe';
import { FiltertemplatePipe } from './pipes/filtertemplate.pipe';
import { ViewAppComponent } from './views/view-app/view-app.component';
import { OrderBydatePipe } from './pipes/order-bydate.pipe';
import { OrderBydatebyPipe } from './pipes/order-bydateby.pipe';
import { AppCustomizationComponent } from './views/app-customization/app-customization.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    NgbTabsetModule,
    MatDialogModule,
    MatButtonModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    LoaderComponent,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    ListAccountsComponent,
    // LecturerAccountComponent,
    BuilderComponent,
    AppAccountComponent,
    AppSpecificStatisticsComponent,
    ChangePasswordComponent,
    DeveloperAccountsComponent,
    EditAppAccountComponent,
    EditDeveloperListComponent,
    EditListComponent,
    EditStaffAccountComponent,
    ErrorPageComponent,
    ListAppComponent,
    ListDeveloperComponent,
    ListStaffDeveloperComponent,
    StaffAccountsComponent,
    StatisticsComponent,
    SystemWideStatisticsComponent,
    AccessLabelComponent,
    AccMagaComponent,
    ConfirmDialogComponent,
    ManageCategoriesComponent,
    EditAccessLabelComponent,
    ChangeLecturerPasswordComponent,
    ChangeDeveloperPasswordComponent,
    ChangeStaffPasswordComponent,
    ListEmailsComponent,
    EditEmailsComponent,
    EditEmailsDeveloperComponent,
    EditEmailsStaffComponent,
    FilternamePipe,
    FilterappsPipe,
    FiltertemplatePipe,
    ViewAppComponent,
    OrderBydatePipe,
    OrderBydatebyPipe,
    AppCustomizationComponent
  ],
  providers: [
    LoaderService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmDialogComponent]
})
export class AppModule {}
