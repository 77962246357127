import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBydateby'
})
export class OrderBydatebyPipe implements PipeTransform {

  transform(value: any[]): any[] {
    return value.sort((a: any, b: any) => b.created_at.N.localeCompare(a.created_at.N));
}

}
