import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'kt-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  constructor(
  private router: Router)
   { }

  ngOnInit() {
  }
    goBack() {
    window.history.back();
  }
    resetPreview() {
    this.router.navigate(["/dashboard"]);
  }

}
