import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";
import { MatDialog } from "@angular/material/dialog";
import * as $ from 'jquery';

import {
  ConfirmDialogModel,
  ConfirmDialogComponent
} from "../../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "kt-list-app",
  templateUrl: "./list-app.component.html",
  styleUrls: ["./list-app.component.scss"]
})
export class ListAppComponent implements OnInit {
  searchText: any;
  constructor(
    private routes: Router,
    private api: ApiService,
    private store: StorageService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.appslist();
  }
  
  apps: any = [];
  apps_list: any = [];
  pagenumbers: any = ''
  pagenumber: any = ''

  appslist() {
    try {
      this.api
        .postmethod("user/allapps/application", {
          sessionid: this.store.get_token()
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.apps = []
            this.apps_list = res.data.Items
            console.log(this.apps_list)
            // this.listqty = 10
            // this.selected = 1
            
            // this.apps_list.forEach((data, index)=>{
            //   if(index<this.listqty){
            //     this.apps.push(data);
            //   }
            // });
            
            // this.pagenumbers = '';
            // this.pagenumber = '';
            // this.pagenumbers = this.api.getpageslength(
            //    this.apps_list.length
            // );

            // this.pagenumber = this.api.getpagelength(
            //    this.apps_list.length
            // );
          }else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }

  searchApp(){
    let _that = this;
    let search_val = $.trim(_that.searchText);
    _that.apps = [];

    this.apps_list.forEach(function(val,index){
      if($.trim(val.appname.S).indexOf(search_val)){
        _that.apps.push(val);
      }
    });

    if($.trim(search_val)==""){
     this.apps_list.forEach((data, index)=>{
          if(index <= 10){
             _that.apps.push(data);
          }
      });
    }
  }

  appView() {
    this.routes.navigate(["/accmaga"]);
  }
  addNewApp() {
    this.routes.navigate(["/accmaga"]);
  }
  goBack() {
    window.history.back();
  }
  selected: any;
  isActive(item) {
    return this.selected === item;
};

gotopage(pagenumber){
    // return
    this.selected = pagenumber
    if(this.apps_list.length>this.listqty){
      this.apps = []
      this.apps_list.forEach((data, index)=>{
        if(index>=this.api.getlength(pagenumber, this.listqty)[0] && index<this.api.getlength(pagenumber, this.listqty)[1]){
          this.apps.push(data)
        }
       })
    }
  }
  
  listApp(data) {
    this.routes.navigate(["/editappaccount"], {
      queryParams: {
        data: JSON.stringify(data)
      }
    });
  }

  reassignapp(app: any) {
//   debugger
    try {
      this.api
        .postmethod("user/reassign/application", {
          sessionid: this.store.get_token(),
          // lusername: data,
          // xlusername: data1
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
        //   debugger
            this.api.popover(res.message);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }

  deleteapp(data: any, data1: any) {
    try {
      this.api
        .postmethod("user/delete/application", {
          sessionid: this.store.get_token(),
          lusername: data,
          xlusername: data1
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            this.appslist();
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  unassignlect(data, data1, app){
    try {
      this.api
        .postmethod("user/unassign/application", {
          sessionid: this.store.get_token(),
          lusername: app.lecturers.SS[0],
          appid: app.Lusername.S,
          appidxl: app.XLusername.S,
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.appslist();
          }
          else{
            this.api.handle_Error(res.message);
          }
        })
      }
      catch(err){
        this.api.handle_Error(err);
      }
  }
  result: string = "";
  confirmDialog(data, data1, app): void {
    if(app.lecturers && app.lecturers.SS && app.lecturers.SS.length){
      alert("Do you want to Delete this App? Then Please un-assign this app first")
    }
    else{
      var message = `Are you sure you want to delete?`;

      var dialogData = new ConfirmDialogModel("", message);
  
      var dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: dialogData
      });
  
      dialogRef.afterClosed().subscribe(dialogResult => {
        this.result = dialogResult;
  
        if (this.result) {
          this.deleteapp(data, data1);
        }
      });
    }
  }

  listqty: any
  changeSelection(){
    this.apps = []
    var tis = this
    this.apps_list.forEach((data, index)=>{
      if(index<tis.listqty){
        tis.apps.push(data)
      }
      if(index == tis.apps_list.length - 1){
        if(tis.apps_list.length<=parseInt(tis.listqty)){
          tis.pagenumber = [1]
        }
        else{
          tis.pagenumber = tis.api.getpagelength(
            tis.apps_list.length
          );
        }
      }
     })
  }
}
