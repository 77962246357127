import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-edit-access-label',
  templateUrl: './edit-access-label.component.html',
  styleUrls: ['./edit-access-label.component.css']
})
export class EditAccessLabelComponent implements OnInit {

  constructor(private router: Router)
   { }

  ngOnInit(): void {
  }
  goBack() {
    window.history.back();
  }

  resetPreview() {
    this.router.navigate(["/staffdeveloper"]);
  }

}
