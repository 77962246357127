import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { StorageService } from "./services/storage.service";

@Component({
  // tslint:disable-next-line
  selector: "body",
  template: "<router-outlet></router-outlet><angular-loader></angular-loader>"
})
export class AppComponent implements OnInit {
  constructor(private store: StorageService,private router: Router) {
    if(this.store.have_token() == false){
      this.router.navigate(["/login"]);
    }}

  ngOnInit() {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
