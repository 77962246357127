import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";

@Component({
  selector: "kt-edit-list",
  templateUrl: "./edit-list.component.html",
  styleUrls: ["./edit-list.component.scss"]
})
export class EditListComponent implements OnInit {
  sub: any;
  editlistForm: FormGroup;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private store: StorageService
  ) {
  }
  appid
  ngOnInit() {
    var query: any = this.route.queryParams;
    this.sub = JSON.parse(query.value.data);
    if(this.sub.appid && this.sub.appid.S){
      this.appid = this.sub.appid.S
    }
    else{
      this.appid = ""
    }
    console.log(this.sub);
    this.editlistForm = this.formBuilder.group({
      username: [this.sub.username.S, [Validators.required]],
      firstname: [this.sub.firstname.S, [Validators.required]],
      lastname: [this.sub.lastname.S, [Validators.required]],
      email: [this.sub.email.S, [Validators.required]],
      enablestatus: [this.sub.enablestatus.BOOL, [Validators.required]],
      appid: [this.appid]
    });
    this.getApps(this.appid);
    this.editlistForm.get('appid').valueChanges.subscribe(val => {
      console.log(this.apps[this.apps.findIndex(e=>(e.Lusername.S == val))]);
      this.appidxl = this.apps[this.apps.findIndex(e=>(e.Lusername.S == val))].XLusername.S
    });
  }
  appidxl: any
  pappidxl: any
  get f() {
    return this.editlistForm.controls;
  }
  goBack() {
    window.history.back();
  }

  apps: any;
  getApps(Lusername) {
    this.apps = []
    if(Lusername){
      try {
        this.api
          .postmethod("user/singleapp/application", {
            sessionid: this.store.get_token(),
            Lusername: Lusername
          })
          .subscribe((res: any) => {
            if (res.status == "success" && res.code == 200) {
              if(res.data.Items[0] && res.data.Items[0].XLusername && res.data.Items[0].XLusername.S){
                this.pappidxl = res.data.Items[0].XLusername.S
                this.apps.push(res.data.Items[0])
                this.appidxl = this.apps[this.apps.findIndex(e=>(e.Lusername.S == this.appid))].XLusername.S
              }
            } else {
              this.api.handle_Error(res);
            }
          });
      } catch (err) {
        this.api.handle_Error(err);
      }
    }
 
    try {
      this.api
        .postmethod("user/all/application", {
          sessionid: this.store.get_token()
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
             res.data.Items.forEach((data, index)=>{
              this.apps.push(data)
            });
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  sendLecturerEmail(data)
  {
   data = this.sub
    // confirm("Send Account Creation Email to Lecturer?");
    try {
      this.api
        .postmethod("user/register/signupemail", {
          sessionid: this.store.get_token(),
          email: data.email.S,
          developerpush: data.notification.BOOL,
          template_type: 'lecturer',
          template_name: 'createlecturer',
          enable_status: data.lect_status.BOOL,
          firstname: data.firstname.S,
          lastname: data.lastname.S,
          password: data.password.S,
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  selected :any;
  select(item) {
    console.log(item)
    this.selected = item; 
};
isActive(item) {
    return this.selected === item;
};
  model: any;
  onSubmit() {
    this.submitted = true;
    if (this.editlistForm.invalid) {
      return;
    }
    var status;
    if (
      this.editlistForm.value.enablestatus == "true" ||
      this.editlistForm.value.enablestatus == true
    ) {
      status = true;
    } else {
      status = false;
    }
//   debugger;
    this.selected = 'submit'
    try {
      this.api
        .postmethod("user/update/lecturer", {
          sessionid: this.store.get_token(),
          username: this.editlistForm.value.username,
          email: this.editlistForm.value.email,
          appid: this.editlistForm.value.appid,
          pappid: this.appid,
          firstname: this.editlistForm.value.firstname,
          lastname: this.editlistForm.value.lastname,
          status: status,
          lusername: this.sub.Lusername.S,
          xlusername: this.sub.XLusername.S,
          appidxl: this.appidxl,
          pappidxl: this.pappidxl,
          developerpush: this.sub.notification.BOOL,
        })
        .subscribe((res: any) => {
          this.selected = ''
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            this.router.navigate(["/listaccounts"]);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.selected = ''
      this.api.handle_Error(err);
    }
  }

  resetPreview() {
    this.router.navigate(["/listaccounts"]);
  }
}
