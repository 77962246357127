import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";
@Component({
  selector: "kt-builder",
  templateUrl: "./builder.component.html",
  styleUrls: ["./builder.component.scss"]
})
export class BuilderComponent implements OnInit {
  model: any;
  submitted = false;
  lecturerForm: FormGroup;
  constructor(
    private api: ApiService,
    private store: StorageService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.getApps();
  }

  get f() {
    return this.lecturerForm.controls;
  }

  resetPreview() {
    this.router.navigate(["/listaccounts"]);
  }
  goBack() {
    window.history.back();
  }

  xlname:any;
  ngOnInit(): void {
    this.lecturerForm = this.formBuilder.group({
      username: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6)]],
      cpassword: ["", [Validators.required, Validators.minLength(6)]],
      firstname: ["", [Validators.required]],
      lastname: ["", [Validators.required]],
      app: [""],
      notification: ["true", [Validators.required]],
      enablestatus: ["true", [Validators.required]]
    },{validator: this.checkIfMatchingPasswords('password', 'cpassword')});
    this.lecturerForm.get('app').valueChanges.subscribe(val => {
      console.log(this.apps[this.apps.findIndex(e=>(e.Lusername.S == val))]);
      this.xlname = this.apps[this.apps.findIndex(e=>(e.Lusername.S == val))].XLusername.S
    });
  }
  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
          passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({notEquivalent: true})
      }
      else {
          return passwordConfirmationInput.setErrors(null);
      }
    }
  }
  apps: any;
  getApps() {
    try {
      this.api
        .postmethod("user/all/application", {
          sessionid: this.store.get_token()
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.apps = res.data.Items;
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  selected :any;
  select(item) {
    console.log(item)
    this.selected = item; 
};
isActive(item) {
    return this.selected === item;
};
  submitPreview() {
    this.submitted = true;
    if (this.lecturerForm.invalid) {
      return;
    }
    if (this.lecturerForm.value.password != this.lecturerForm.value.cpassword) {
      return alert("Password and confirm password should be same");
    }
    var notification;
    if (this.lecturerForm.value.notification == "true") {
      notification = true;
    } else {
      notification = false;
    }
    var enablestatus;
    if (this.lecturerForm.value.enablestatus == "true") {
      enablestatus = true;
    } else {
      enablestatus = false;
    }
    this.selected = 'submit'
    try {
      this.api
        .postmethod("user/register/lecturer", {
          username: this.lecturerForm.value.username,
          firstname: this.lecturerForm.value.firstname,
          lastname: this.lecturerForm.value.lastname,
          notification: notification,
          app: this.lecturerForm.value.app,
          applname: this.lecturerForm.value.app,
          appxlname: this.xlname,
          status: enablestatus,
          password: this.lecturerForm.value.password,
          email: this.lecturerForm.value.email,
          timestamp: Date.now(),
          created: Date.now(),
          sessionid: this.store.get_token()
        })
        .subscribe((res: any) => {
          this.selected = ''
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            this.router.navigate(["/listaccounts"]);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.selected = ''
      this.api.handle_Error(err);
    }
  }
}
