import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class StorageService {

  is_login: boolean;

  constructor() {}



  have_token() {
    if (localStorage.getItem("token")) {
      return true;
    } else {
      return false;
    }
  }
  set_token(token) {
    localStorage.setItem("token", token);
  }
  get_token() {
    if (localStorage.getItem("token")) {
      return localStorage.getItem("token");
    }
  }
  remove_token() {
    if (localStorage.getItem("token")) {
      localStorage.removeItem("token");
    }
  }
  user_type(){
    return "admin"
  }

  // User Data

  setuserdata(data) {
    localStorage.setItem("userlocaldata", JSON.stringify(data));
  }

  getuserdata() {
    if (localStorage.getItem("userlocaldata")) {
      return JSON.parse(localStorage.getItem("userlocaldata"));
    }
  }

  removeuserdata() {
    if (localStorage.getItem("userlocaldata")) {
      localStorage.removeItem("userlocaldata");
    }
  }
}
