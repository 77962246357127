import { INavData } from '@coreui/angular';

  var menu = [

    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
    },
  
    {
      name: 'Lecturer Accounts',
      url: '/base',
      icon: 'icon-user',
      
      children: [
        {
          name: 'Create Account',
          url: '/lecturer_accounts',
          icon: 'icon-magnifier-add',
        },
        {
          name: 'List Accounts',
          url: '/listaccounts',
          icon: 'icon-list'
        },
      ]
    },
    {
      name: 'App Accounts',
      url: '/buttons',
      icon: 'icon-layers',
      children: [
        //{
         // name: 'Add App Account',
         // url: '/accmaga',
          //icon: 'icon-magnifier-add'
       // },
        {
          name: 'App Customization',
          url: '/appcustomization',
          icon: 'icon-magnifier-add'
        },
        {
          name: 'List Apps',
          url: '/listapps',
          icon: 'icon-list'
        },     
      ]
    },
    {
      name: 'Developer Accounts ',
      url: '/developeraccounts',
      icon: 'icon-folder',
      children: [
        {
          name: 'Add Account',
          url: '/developeraccounts',
          icon: 'icon-plus'
        },
        {
          name: 'List Developers',
          url: '/listdeveloper',
          icon: 'icon-list'
        },
      ]
    },
  
    {
      name: 'Staff Accounts',
      url: '/notifications',
      icon: 'icon-people',
      children: [
        {
          name: 'Add Account',
          url: '/staffaccount',
          icon: 'icon-plus'
        },
        {
          name: 'List Developers',
          url: '/staffdeveloper',
          icon: 'icon-list'
        },
      ]
    },
    {
      name: 'Manage Emails',
      url: '/listemails',
      icon: 'icon-people',
      children: [
        {
          name: 'List Emails',
          url: '/listemails',
          icon: 'icon-list'
        }
      ]
    },
    {
      name: 'Statistics',
      url: '/systemtatistics',
      icon: 'icon-chart',
      children: [
        {
          name: 'System Wide',
          url: '/systemtatistics',
          icon: 'icon-eye'
        },
        {
          name: 'App Specific Statistics',
          url: '/specificstatistics',
          icon: 'icon-eye'
        },
      ]
    },
    {
      divider: true
    }
  
  ];



export const navItems: INavData[] = menu;