import { Component, OnInit,VERSION ,ViewChild,ElementRef } from '@angular/core';
import { Router } from "@angular/router";
import * as $ from 'jquery';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType } from "@angular/common/http";

import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";

import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-app-customization',
  templateUrl: './app-customization.component.html',
  styleUrls: ['./app-customization.component.scss']
})

export class AppCustomizationComponent implements OnInit {
  @ViewChild('myCanvas')
  myCanvas: ElementRef<HTMLCanvasElement>;
  public context: CanvasRenderingContext2D;
  submitted = false;
  submittedFC = false;
  submittedDesignControl = false;
  submittediOS = false;
  submittedAndroid = false;
  custForm: FormGroup;
  appConnectionForm:FormGroup;
  desginControlForm: FormGroup;
  featureControlForm: FormGroup;
  iOForm: FormGroup;
  androidForm: FormGroup;
  imageSrc = '';
  percentDone: number;
  uploadSuccess: boolean;
  size:any;
  width:number;
  height:number;
  enable_upload_field=false;
  candownloadapk: boolean = false
  candownloadios: boolean = false
  @ViewChild('coverFilesInput') imgType:ElementRef;
  version = VERSION;

  constructor(
    private api: ApiService,
    private store: StorageService,
  	private formBuilder: FormBuilder,
    private router: Router,
    private httpClient: HttpClient,
  	) { 

    }
  ngOnDestroy(){
    // this.custForm.reset();
    // this.featureControlForm.reset();
    // this.iOForm.reset();
    // this.androidForm.reset();
    // this.appConnectionForm.reset();
    // this.desginControlForm.reset();
  }
  ngOnInit(): void {
    this.getlecturers()
  	this.custForm = this.formBuilder.group({
      appname: ["", [Validators.required]],
      about_us: ["About us", [Validators.required]],
      welcome: ["Welcome", [Validators.required]],
      term_and_condition: ["Terms and Condition", [Validators.required]],
      contact_email: ["info@shiur.cloud", [Validators.required]],
      app_logo_file_choose: [""]
    });

    this.featureControlForm = this.formBuilder.group({
      allow_comments:['true'],
      restrict_content:['false']
    });

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    let today_date = dd + '/' + mm + '/' + yyyy;
    
    this.appConnectionForm = this.formBuilder.group({
      app_lunch_data:[today_date,""],
      lecturer:[""],
      upload_wireframe_file:["",""]
    });       

    this.desginControlForm = this.formBuilder.group({
      select_theme:["light", [Validators.required]],
      color_combination:["#606060", [Validators.required]],
      color_combination_two:["#9f9999", [Validators.required]],    
      design_control_upload_splash: [""],
      design_control_background_image:[""],
      design_control_otp_screen:[""],
      design_control_internal_pages:[""],
      design_control_internal_pages_background_image:[""]
    }); 

    //for iOS form
    this.iOForm = this.formBuilder.group({
      ios_app_name: ["", [Validators.required]],
      ios_app_subtitle: ["", [Validators.required]],
      ios_app_submission_category: ["Education", [Validators.required]],
      ios_app_submission_sub_category: ["Lifestyle", [Validators.required]],
      ios_default_language: ["English", [Validators.required]],
      ios_country_availability: ["All", [Validators.required]],
      ios_privacy_policy_page_link: ["https://api.shiur.cloud:5000/user/privacy/policy?appid=", [Validators.required]],
      ios_app_keywords: ["", [Validators.required]],
      ios_app_description: ["", [Validators.required]],
      ios_app_support_url: ["https://signup.shiur.cloud/", [Validators.required]],
      ios_app_marketing_url: ["https://signup.shiur.cloud/", [Validators.required]],
      ios_app_icon: [""],
      ios_screenshot_iphone51: [""],
      ios_screenshot_iphone52: [""],
      ios_screenshot_iphone53: [""],
      ios_screenshot_iphone61: [""],
      ios_screenshot_iphone62: [""],
      ios_screenshot_iphone63: [""],
      ios_screenshot_ipad1: [""],
      ios_screenshot_ipad2: [""],
      ios_screenshot_ipad3: [""],
      ios_promotion_video_url: [""],
      ios_app_version: ["0.0.1", [Validators.required]],
      ios_app_rating: ["4+", [Validators.required]],
      ios_price: ["0", [Validators.required]]
    });  

    //for Android form
    this.androidForm = this.formBuilder.group({
      android_app_name: ["", [Validators.required]],
      android_app_subtitle: ["", [Validators.required]],
      android_app_submission_category: ["Education", [Validators.required]],
      android_app_submission_sub_category: ["Education", [Validators.required]],
      android_default_language: ["English", [Validators.required]],
      android_country_availability: ["All", [Validators.required]],
      android_privacy_policy_page_link: ["https://api.shiur.cloud:5000/user/privacy/policy?appid=", [Validators.required]],
      android_app_keywords: ["", [Validators.required]],
      android_app_description: ["", [Validators.required]],
      android_app_support_url: ["https://signup.shiur.cloud/", [Validators.required]],
      android_app_marketing_url: ["https://signup.shiur.cloud/", [Validators.required]],
      android_app_icon: [""],
      android_screenshot_phone1: [""],
      android_screenshot_phone2: [""],
      android_screenshot_phone3: [""],
      android_screenshot_tablet1: [""],
      android_screenshot_tablet2: [""],
      android_screenshot_tablet3: [""],
      android_screenshot_featuredimage: [""],
      android_promotion_video_url: [""],
      android_app_version: ["0.0.1", [Validators.required]],
      android_app_rating: ["3+", [Validators.required]],
      android_price: ["0", [Validators.required]]
    }); 
  }
  ios_app_icon: any
  ios_screenshot_iphone51: any
  ios_screenshot_iphone52: any
  ios_screenshot_iphone53: any
  ios_screenshot_iphone61: any
  ios_screenshot_iphone62: any
  ios_screenshot_iphone63: any
  ios_screenshot_ipad1: any
  ios_screenshot_ipad2: any
  ios_screenshot_ipad3: any
  android_app_icon: any
  android_screenshot_phone1: any
  android_screenshot_phone2: any
  android_screenshot_phone3: any
  android_screenshot_tablet1: any
  android_screenshot_tablet2: any
  android_screenshot_tablet3: any
  android_screenshot_featuredimage: any
  @ViewChild('iphone51')
  iphone51: ElementRef<HTMLCanvasElement>;
  public contextiphone51: CanvasRenderingContext2D;
  @ViewChild('iphone52')
  iphone52: ElementRef<HTMLCanvasElement>;
  public contextiphone52: CanvasRenderingContext2D;
  @ViewChild('iphone53')
  iphone53: ElementRef<HTMLCanvasElement>;
  public contextiphone53: CanvasRenderingContext2D;
  @ViewChild('iphone61')
  iphone61: ElementRef<HTMLCanvasElement>;
  public contextiphone61: CanvasRenderingContext2D;
  @ViewChild('iphone62')
  iphone62: ElementRef<HTMLCanvasElement>;
  public contextiphone62: CanvasRenderingContext2D;
  @ViewChild('iphone63')
  iphone63: ElementRef<HTMLCanvasElement>;
  public contextiphone63: CanvasRenderingContext2D;
  @ViewChild('ipad1')
  ipad1: ElementRef<HTMLCanvasElement>;
  public contextipad1: CanvasRenderingContext2D;
  @ViewChild('ipad2')
  ipad2: ElementRef<HTMLCanvasElement>;
  public contextipad2: CanvasRenderingContext2D;
  @ViewChild('ipad3')
  ipad3: ElementRef<HTMLCanvasElement>;
  public contextipad3: CanvasRenderingContext2D;
  @ViewChild('androidphone1')
  androidphone1: ElementRef<HTMLCanvasElement>;
  public contextandroid1: CanvasRenderingContext2D;
  @ViewChild('androidphone2')
  androidphone2: ElementRef<HTMLCanvasElement>;
  public contextandroid2: CanvasRenderingContext2D;
  @ViewChild('androidphone3')
  androidphone3: ElementRef<HTMLCanvasElement>;
  public contextandroid3: CanvasRenderingContext2D;
  @ViewChild('tablet1')
  tablet1: ElementRef<HTMLCanvasElement>;
  public contexttablet1: CanvasRenderingContext2D;
  @ViewChild('tablet2')
  tablet2: ElementRef<HTMLCanvasElement>;
  public contexttablet2: CanvasRenderingContext2D;
  @ViewChild('tablet3')
  tablet3: ElementRef<HTMLCanvasElement>;
  public contexttablet3: CanvasRenderingContext2D;
  @ViewChild('featuredimage')
  featuredimage: ElementRef<HTMLCanvasElement>;
  public contextfeaturedimage: CanvasRenderingContext2D;
  fillappname(){
    if(this.custForm.value.appname){
      this.androidForm.controls['android_app_name'].setValue(this.custForm.value.appname);
      this.androidForm.controls['android_app_subtitle'].setValue(this.custForm.value.appname);
      this.androidForm.controls['android_app_description'].setValue(this.custForm.value.appname+" app is mobile resource for listening to shiurim.");
      this.androidForm.controls['android_app_keywords'].setValue(this.custForm.value.appname);

      this.iOForm.controls['ios_app_name'].setValue(this.custForm.value.appname);
      this.iOForm.controls['ios_app_subtitle'].setValue(this.custForm.value.appname);
      this.iOForm.controls['ios_app_description'].setValue(this.custForm.value.appname+" app is mobile resource for listening to shiurim.");
      this.iOForm.controls['ios_app_keywords'].setValue(this.custForm.value.appname);
      

      // var tCtx: any = document.getElementById('textCanvas').getContext('2d')
      this.context = this.myCanvas.nativeElement.getContext('2d');
      var androidimageElem: any = document.getElementById('android-upload-media-image');
      var iosimageElem: any = document.getElementById('ios-upload-media-image');
      var imageElem: any = document.getElementById('upload-media-image-logo');
      

      this.context.canvas.width = 1024;
      this.context.canvas.height = 1024;
      this.context.font = "130px Arial";
      var name = this.custForm.value.appname.toString()
      if(this.custForm.value.appname.length<15){
        this.context.fillText(this.custForm.value.appname, this.context.canvas.width/2 - this.context.measureText(this.custForm.value.appname).width/2, this.context.canvas.height/2);
      }
      else{
        var arr: any = name.split(" ");
        var tis = this
        arr.forEach((data, index)=>{
          tis.context.fillText(data, tis.context.canvas.width/2 - tis.context.measureText(data).width/2, tis.context.canvas.height/3+(index*140));
        })
      }
        androidimageElem.src = this.context.canvas.toDataURL();
        iosimageElem.src = this.context.canvas.toDataURL();
        imageElem.src = this.context.canvas.toDataURL();
        this.android_app_icon = this.createblobfile(androidimageElem.src)
        this.ios_app_icon = this.createblobfile(iosimageElem.src)

    // Featured Image
  
      var imageElemf: any = document.getElementById('upload-media-image-featuredimage');
      this.contextfeaturedimage = this.featuredimage.nativeElement.getContext('2d');
      this.contextfeaturedimage.canvas.width = 1024;
      this.contextfeaturedimage.canvas.height = 500;
      var tis = this
      tis.contextfeaturedimage.font = "70px Arial";
      tis.contextfeaturedimage.fillText(tis.custForm.value.appname, tis.contextfeaturedimage.canvas.width/2 - tis.contextfeaturedimage.measureText(tis.custForm.value.appname).width/2, tis.contextfeaturedimage.canvas.height/2);  
      imageElemf.src = tis.contextfeaturedimage.canvas.toDataURL();
      tis.android_screenshot_featuredimage = tis.createblobfile(imageElemf.src)

// Screenshot 1

    var imageElem1: any = document.getElementById('upload-media-image-iphone51');
    this.contextiphone51 = this.iphone51.nativeElement.getContext('2d');
    this.contextiphone51.canvas.width = 1242;
    this.contextiphone51.canvas.height = 2208;
    var background1 = new Image();
    background1.src = "assets/screenshots/iphone5/1.png";
      var tis = this
    background1.onload = function(){
      tis.contextiphone51.drawImage(background1,0,0); 
      tis.contextiphone51.font = "70px Arial";
      tis.contextiphone51.fillText(tis.custForm.value.appname, tis.contextiphone51.canvas.width/2 - tis.contextiphone51.measureText(tis.custForm.value.appname).width/2, 170);  
      imageElem1.src = tis.contextiphone51.canvas.toDataURL();
      tis.ios_screenshot_iphone51 = tis.createblobfile(imageElem1.src)
    }

    var imageElem2: any = document.getElementById('upload-media-image-iphone61');
    this.contextiphone61 = this.iphone61.nativeElement.getContext('2d');
    this.contextiphone61.canvas.width = 1242;
    this.contextiphone61.canvas.height = 2688;
    var background2 = new Image();
    background2.src = "assets/screenshots/iphone6/1.png";
      var tis = this
    background2.onload = function(){
      tis.contextiphone61.drawImage(background2,0,0); 
      tis.contextiphone61.font = "60px Arial";
      tis.contextiphone61.fillText(tis.custForm.value.appname, tis.contextiphone61.canvas.width/2 - tis.contextiphone61.measureText(tis.custForm.value.appname).width/2, 240);  
      imageElem2.src = tis.contextiphone61.canvas.toDataURL();
      tis.ios_screenshot_iphone61 = tis.createblobfile(imageElem2.src)
    }

    var imageElem3: any = document.getElementById('upload-media-image-ipad1');
    this.contextipad1 = this.ipad1.nativeElement.getContext('2d');
    this.contextipad1.canvas.width = 2048;
    this.contextipad1.canvas.height = 2732;
    var background3 = new Image();
    background3.src = "assets/screenshots/ipad/1.png";
      var tis = this
    background3.onload = function(){
      tis.contextipad1.drawImage(background3,0,0); 
      tis.contextipad1.font = "55px Arial";
      tis.contextipad1.fillText(tis.custForm.value.appname, tis.contextipad1.canvas.width/2 - tis.contextipad1.measureText(tis.custForm.value.appname).width/2, 120);  
      imageElem3.src = tis.contextipad1.canvas.toDataURL();
      tis.ios_screenshot_ipad1 = tis.createblobfile(imageElem3.src)
    }

    var imageElem10: any = document.getElementById('upload-media-image-androidphone1');
    this.contextandroid1 = this.androidphone1.nativeElement.getContext('2d');
    this.contextandroid1.canvas.width = 1080;
    this.contextandroid1.canvas.height = 1920;
    var background10 = new Image();
    background10.src = "assets/screenshots/androidphone/1.png";
      var tis = this
      background10.onload = function(){
      tis.contextandroid1.drawImage(background10,0,0); 
      tis.contextandroid1.font = "55px Arial";
      tis.contextandroid1.fillText(tis.custForm.value.appname, 140, 155);  
      imageElem10.src = tis.contextandroid1.canvas.toDataURL();
      tis.android_screenshot_phone1 = tis.createblobfile(imageElem10.src)
    }

    var imageElem11: any = document.getElementById('upload-media-image-tablet1');
    this.contexttablet1 = this.tablet1.nativeElement.getContext('2d');
    this.contexttablet1.canvas.width = 1200;
    this.contexttablet1.canvas.height = 1920;
    var background11 = new Image();
    background11.src = "assets/screenshots/tablet/1.png";
      var tis = this
      background11.onload = function(){
      tis.contexttablet1.drawImage(background11,0,0); 
      tis.contexttablet1.font = "55px Arial";
      tis.contexttablet1.fillText(tis.custForm.value.appname, 140, 130);  
      imageElem11.src = tis.contexttablet1.canvas.toDataURL();
      tis.android_screenshot_tablet1 = tis.createblobfile(imageElem11.src)
    }
// Screenshot 2

    var imageElem4: any = document.getElementById('upload-media-image-iphone52');
    this.contextiphone52 = this.iphone52.nativeElement.getContext('2d');
    this.contextiphone52.canvas.width = 1242;
    this.contextiphone52.canvas.height = 2208;
    var background4 = new Image();
    background4.src = "assets/screenshots/iphone5/2.png";
      var tis = this
    background4.onload = function(){
      tis.contextiphone52.drawImage(background4,0,0); 
      tis.contextiphone52.font = "40px Arial";
      tis.contextiphone52.fillStyle = "#607D8B"
      var html = `SHIUR CLOUD (PTY) LTD built the Shiurim app as a Free`
      html = html.replace(/Shiurim/g, tis.custForm.value.appname)
      tis.contextiphone52.fillText(html, 75, 500);  
      var html1 = ` app. This SERVICE is provided by SHIUR CLOUD (PTY) LTD`
      tis.contextiphone52.fillText(html1, 75, 590);  
      var html2 = ` at no cost and is intended for use as is.`
      tis.contextiphone52.fillText(html2, 75, 680);  
      imageElem4.src = tis.contextiphone52.canvas.toDataURL();
      tis.ios_screenshot_iphone52 = tis.createblobfile(imageElem4.src)
    }
    
    var imageElem5: any = document.getElementById('upload-media-image-iphone62');
    this.contextiphone62 = this.iphone62.nativeElement.getContext('2d');
    this.contextiphone62.canvas.width = 1242;
    this.contextiphone62.canvas.height = 2688;
    var background5 = new Image();
    background5.src = "assets/screenshots/iphone6/2.png";
      var tis = this
    background5.onload = function(){
      tis.contextiphone62.drawImage(background5,0,0); 
      tis.contextiphone62.font = "40px Arial";
        tis.contextiphone62.fillStyle = "#607D8B"
        var html = `SHIUR CLOUD (PTY) LTD built the Shiurim app as a Free`
        html = html.replace(/Shiurim/g, tis.custForm.value.appname)
        tis.contextiphone62.fillText(html, 75, 500);  
        var html1 = ` app. This SERVICE is provided by SHIUR CLOUD (PTY) LTD`
        tis.contextiphone62.fillText(html1, 75, 590);  
        var html2 = ` at no cost and is intended for use as is.`
        tis.contextiphone62.fillText(html2, 75, 680);  
        imageElem5.src = tis.contextiphone62.canvas.toDataURL();
        tis.ios_screenshot_iphone62 = tis.createblobfile(imageElem5.src)
    }

    var imageElem6: any = document.getElementById('upload-media-image-ipad2');
    this.contextipad2 = this.ipad2.nativeElement.getContext('2d');
    this.contextipad2.canvas.width = 2048;
    this.contextipad2.canvas.height = 2732;
    var background6 = new Image();
    background6.src = "assets/screenshots/ipad/2.png";
      var tis = this
    background6.onload = function(){
      tis.contextipad2.drawImage(background6,0,0); 
      tis.contextipad2.font = "30px Arial";
      tis.contextipad2.fillStyle = "#607D8B"
      var html = `SHIUR CLOUD (PTY) LTD built the Shiurim app as a Free app. This SERVICE is provided by SHIUR CLOUD (PTY) LTD at no cost`
      html = html.replace(/Shiurim/g, tis.custForm.value.appname)
      tis.contextipad2.fillText(html, 60, 340);  
      var html1 = ` and is intended for use as is.`
      tis.contextipad2.fillText(html1, 60, 400);  
      imageElem6.src = tis.contextipad2.canvas.toDataURL();
      tis.ios_screenshot_ipad2 = tis.createblobfile(imageElem6.src)
    }

    var imageElem12: any = document.getElementById('upload-media-image-androidphone2');
    this.contextandroid2 = this.androidphone2.nativeElement.getContext('2d');
    this.contextandroid2.canvas.width = 1080;
    this.contextandroid2.canvas.height = 1920;
    var background12 = new Image();
    background12.src = "assets/screenshots/androidphone/2.png";
      var tis = this
      background12.onload = function(){
      tis.contextandroid2.drawImage(background12,0,0); 
      tis.contextandroid2.font = "40px Arial";
      tis.contextandroid2.fillStyle = "#607D8B"
      var html = `SHIUR CLOUD (PTY) LTD built the Shiurim app as a `
      html = html.replace(/Shiurim/g, tis.custForm.value.appname)
      tis.contextandroid2.fillText(html, 60, 400);  
      var html1 = `Free app. This SERVICE is provided by SHIUR `
      tis.contextandroid2.fillText(html1, 60, 460); 
      var html2= `CLOUD (PTY) LTD at no cost and is intended for `
      tis.contextandroid2.fillText(html2, 60, 520);  
      var html3= `use as is.`
      tis.contextandroid2.fillText(html3, 60, 580);  
      imageElem12.src = tis.contextandroid2.canvas.toDataURL();
      tis.android_screenshot_phone2 = tis.createblobfile(imageElem12.src)
    }

    var imageElem13: any = document.getElementById('upload-media-image-tablet2');
    this.contexttablet2 = this.tablet2.nativeElement.getContext('2d');
    this.contexttablet2.canvas.width = 1200;
    this.contexttablet2.canvas.height = 1920;
    var background13 = new Image();
    background13.src = "assets/screenshots/tablet/2.png";
      var tis = this
      background13.onload = function(){
      tis.contexttablet2.drawImage(background13,0,0); 
      tis.contexttablet2.font = "30px Arial";
      tis.contexttablet2.fillStyle = "#607D8B"
      var html = `SHIUR CLOUD (PTY) LTD built the Shiurim app as a Free app. This SERVICE is`
      html = html.replace(/Shiurim/g, tis.custForm.value.appname)
      tis.contexttablet2.fillText(html, 60, 340);  
      var html1 = `provided by SHIUR CLOUD (PTY) LTD at no cost and is intended for use as is.`
      tis.contexttablet2.fillText(html1, 60, 400);  
      imageElem13.src = tis.contexttablet2.canvas.toDataURL();
      tis.android_screenshot_tablet2 = tis.createblobfile(imageElem13.src)
    }
  // Screenshot 3
    var imageElem7: any = document.getElementById('upload-media-image-iphone53');
    this.contextiphone53 = this.iphone53.nativeElement.getContext('2d');
    this.contextiphone53.canvas.width = 1242;
    this.contextiphone53.canvas.height = 2208;
    var background7 = new Image();
    background7.src = "assets/screenshots/iphone5/3.png";
      var tis = this
    background7.onload = function(){
      tis.contextiphone53.drawImage(background7,0,0); 
      tis.contextiphone53.font = "40px Arial";
      tis.contextiphone53.fillStyle = "#607D8B"
      var html = `These Terms and Conditions ("Terms", "Terms and Conditions")`
      html = html.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone53.fillText(html, 75, 500);   
      var html1 = ` govern your relationship with the Tech Torahs App mobile `
      html1 = html1.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone53.fillText(html1, 75, 590);   
      var html2 = `application (the "Service") operated by Tech Torahs App `
      html2 = html2.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone53.fillText(html2, 75, 680);   
      var html3 = `("us", "we", or "our"). Please read these Terms and `
      html3 = html3.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone53.fillText(html3, 75, 770);  
      var html4 = `Conditions carefully before using our Tech Torahs `
      html4 = html4.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone53.fillText(html4, 75, 860);   
      var html5 = `App mobile application (the "Service").`
      html5 = html5.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone53.fillText(html5, 75, 950);   
      var html6 = `Your access to and use of the Service is conditioned `
      html6 = html6.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone53.fillText(html6, 75, 1040);   
      var html7 = `on your acceptance of and compliance with these Terms.`
      html7 = html7.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone53.fillText(html7, 75, 1150);   
      var html7 = ` These Terms apply to all visitors, users and others`
      html7 = html7.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone53.fillText(html7, 75, 1240);   
      var html8 = ` who access or use the Service.`
      html8 = html8.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone53.fillText(html8, 75, 1330);   
      var html9 = `By accessing or using the Service you agree to be bound`
      html9 = html9.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone53.fillText(html9, 75, 1420);   
      var html10 = ` by these Terms. If you disagree with any part of the terms`
      html10 = html10.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone53.fillText(html10, 75, 1510);   
      var html11 = ` then you may not access the Service.`
      html11 = html11.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone53.fillText(html11, 75, 1600);   
      var html12 = `Contact Us`
      html12 = html12.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone53.fillText(html12, 75, 1690);   
      var html13 = `If you have any questions, please contact us.`
      html13 = html13.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone53.fillText(html13, 75, 1780);   
      imageElem7.src = tis.contextiphone53.canvas.toDataURL();
      tis.ios_screenshot_iphone53 = tis.createblobfile(imageElem7.src)
    }


    var imageElem8: any = document.getElementById('upload-media-image-iphone63');
    this.contextiphone63 = this.iphone63.nativeElement.getContext('2d');
    this.contextiphone63.canvas.width = 1242;
    this.contextiphone63.canvas.height = 2688;
    var background8 = new Image();
    background8.src = "assets/screenshots/iphone6/3.png";
      var tis = this
      background8.onload = function(){
      tis.contextiphone63.drawImage(background8,0,0); 
      tis.contextiphone63.font = "40px Arial";
      tis.contextiphone63.fillStyle = "#607D8B"
      var html = `These Terms and Conditions ("Terms", "Terms and Conditions")`
      html = html.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone63.fillText(html, 75, 500);   
      var html1 = ` govern your relationship with the Tech Torahs App mobile `
      html1 = html1.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone63.fillText(html1, 75, 590);   
      var html2 = `application (the "Service") operated by Tech Torahs App `
      html2 = html2.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone63.fillText(html2, 75, 680);   
      var html3 = `("us", "we", or "our"). Please read these Terms and `
      html3 = html3.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone63.fillText(html3, 75, 770);  
      var html4 = `Conditions carefully before using our Tech Torahs `
      html4 = html4.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone63.fillText(html4, 75, 860);   
      var html5 = `App mobile application (the "Service").`
      html5 = html5.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone63.fillText(html5, 75, 950);   
      var html6 = `Your access to and use of the Service is conditioned `
      html6 = html6.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone63.fillText(html6, 75, 1040);   
      var html7 = `on your acceptance of and compliance with these Terms.`
      html7 = html7.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone63.fillText(html7, 75, 1150);   
      var html7 = ` These Terms apply to all visitors, users and others`
      html7 = html7.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone63.fillText(html7, 75, 1240);   
      var html8 = ` who access or use the Service.`
      html8 = html8.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone63.fillText(html8, 75, 1330);   
      var html9 = `By accessing or using the Service you agree to be bound`
      html9 = html9.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone63.fillText(html9, 75, 1420);   
      var html10 = ` by these Terms. If you disagree with any part of the terms`
      html10 = html10.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone63.fillText(html10, 75, 1510);   
      var html11 = ` then you may not access the Service.`
      html11 = html11.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone63.fillText(html11, 75, 1600);   
      var html12 = `Contact Us`
      html12 = html12.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone63.fillText(html12, 75, 1690);   
      var html13 = `If you have any questions, please contact us.`
      html13 = html13.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextiphone63.fillText(html13, 75, 1780);   
      imageElem8.src = tis.contextiphone63.canvas.toDataURL();
      tis.ios_screenshot_iphone63 = tis.createblobfile(imageElem8.src)
    }


    var imageElem9: any = document.getElementById('upload-media-image-ipad3');
    this.contextipad3 = this.ipad3.nativeElement.getContext('2d');
    this.contextipad3.canvas.width = 2048;
    this.contextipad3.canvas.height = 2732;
    var background9 = new Image();
    background9.src = "assets/screenshots/ipad/3.png";
      var tis = this
      background9.onload = function(){
      tis.contextipad3.drawImage(background9,0,0); 
      tis.contextipad3.font = "40px Arial";
      tis.contextipad3.fillStyle = "#607D8B"
      var html = `These Terms and Conditions ("Terms", "Terms and Conditions") govern your relationship with the Tech Torahs`
      html = html.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextipad3.fillText(html, 75, 350);   
      var html1 = ` App mobile application (the "Service") operated by Tech Torahs App ("us", "we", or "our"). Please read these`
      html1 = html1.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextipad3.fillText(html1, 75, 410);   
      var html2 = ` Terms and Conditions carefully before using our Tech Torahs App mobile application (the "Service").`
      html2 = html2.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextipad3.fillText(html2, 75, 470);   
      var html3 = `Your access to and use of the Service is conditioned on your acceptance of and compliance with these `
      html3 = html3.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextipad3.fillText(html3, 75, 530);  
      var html4 = `Terms. These Terms apply to all visitors, users and others who access or use the Service. By accessing or `
      html4 = html4.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextipad3.fillText(html4, 75, 590);   
      var html5 = `using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then `
      html5 = html5.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextipad3.fillText(html5, 75, 650);   
      var html6 = `you may not access the Service.`
      html6 = html6.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextipad3.fillText(html6, 75, 710);   
      var html7 = `Contact Us`
      html7 = html7.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextipad3.fillText(html7, 75, 770);     
      var html8 = `If you have any questions, please contact us.`
      html8 = html8.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextipad3.fillText(html8, 75, 830);     
      imageElem9.src = tis.contextipad3.canvas.toDataURL();
      tis.ios_screenshot_ipad3 = tis.createblobfile(imageElem9.src)
    }


    var imageElem14: any = document.getElementById('upload-media-image-androidphone3');
    this.contextandroid3 = this.androidphone3.nativeElement.getContext('2d');
    this.contextandroid3.canvas.width = 1080;
    this.contextandroid3.canvas.height = 1920;
    var background14 = new Image();
    background14.src = "assets/screenshots/androidphone/3.png";
      var tis = this
      background14.onload = function(){
      tis.contextandroid3.drawImage(background14,0,0); 
      tis.contextandroid3.font = "40px Arial";
      tis.contextandroid3.fillStyle = "#607D8B"
      var html = `These Terms and Conditions ("Terms", "Terms and ")`
      html = html.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextandroid3.fillText(html, 75, 500);   
      var html1 = `Conditions govern your relationship with the Tech Torahs`
      html1 = html1.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextandroid3.fillText(html1, 75, 590);   
      var html2 = `App mobile application (the "Service") operated by `
      html2 = html2.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextandroid3.fillText(html2, 75, 680);   
      var html3 = `Tech Torahs App ("us", "we", or "our"). Please read `
      html3 = html3.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextandroid3.fillText(html3, 75, 770);  
      var html4 = `these Terms and Conditions carefully before using our `
      html4 = html4.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextandroid3.fillText(html4, 75, 860);   
      var html5 = `Tech Torahs App mobile application (the "Service").`
      html5 = html5.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextandroid3.fillText(html5, 75, 950);   
      var html6 = `Your access to and use of the Service is conditioned `
      html6 = html6.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextandroid3.fillText(html6, 75, 1040);   
      var html7 = `on your acceptance of and compliance with these`
      html7 = html7.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextandroid3.fillText(html7, 75, 1150);   
      var html7 = `Terms. These Terms apply to all visitors, users and `
      html7 = html7.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextandroid3.fillText(html7, 75, 1240);   
      var html8 = `others who access or use the Service.`
      html8 = html8.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextandroid3.fillText(html8, 75, 1330);   
      var html9 = `By accessing or using the Service you agree to be `
      html9 = html9.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextandroid3.fillText(html9, 75, 1420);   
      var html10 = `bound by these Terms. If you disagree with any part `
      html10 = html10.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextandroid3.fillText(html10, 75, 1510);   
      var html11 = `of the terms then you may not access the Service.`
      html11 = html11.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contextandroid3.fillText(html11, 75, 1600);   
      imageElem14.src = tis.contextandroid3.canvas.toDataURL();
      tis.android_screenshot_phone3 = tis.createblobfile(imageElem14.src)
    }

    var imageElem15: any = document.getElementById('upload-media-image-tablet3');
    this.contexttablet3 = this.tablet3.nativeElement.getContext('2d');
    this.contexttablet3.canvas.width = 1200;
    this.contexttablet3.canvas.height = 1920;
    var background13 = new Image();
    background13.src = "assets/screenshots/tablet/3.png";
      var tis = this
      background13.onload = function(){
      tis.contexttablet3.drawImage(background13,0,0); 
      tis.contexttablet3.font = "40px Arial";
      tis.contexttablet3.fillStyle = "#607D8B"
      var html = `These Terms and Conditions ("Terms", "Terms and Conditions`
      html = html.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contexttablet3.fillText(html, 75, 350);   
      var html1 = `") govern your relationship with the Tech Torahs App`
      html1 = html1.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contexttablet3.fillText(html1, 75, 410);   
      var html2 = `mobile application (the "Service") operated by Tech Torahs App`
      html2 = html2.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contexttablet3.fillText(html2, 75, 470);   
      var html3 =  `("us", "we", or "our"). Please read these Terms and Conditions`
      html3 = html3.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contexttablet3.fillText(html3, 75, 530);  
      var html4 = `carefully before using our Tech Torahs App mobile application`
      html4 = html4.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contexttablet3.fillText(html4, 75, 590);   
      var html5 = `(the "Service"). Your access to and use of the  Service`
      html5 = html5.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contexttablet3.fillText(html5, 75, 650);   
      var html6 = `is conditioned on your acceptance of and compliance with `
      html6 = html6.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contexttablet3.fillText(html6, 75, 710);   
      var html7 = `these Terms. These Terms apply to all visitors, users and `
      html7 = html7.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contexttablet3.fillText(html7, 75, 770);     
      var html8 = `others who access or use the Service. By accessing or using`
      html8 = html8.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contexttablet3.fillText(html8, 75, 830);     
      var html9 = `the Service you agree to be bound by these Terms. If you`
      html9 = html9.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contexttablet3.fillText(html9, 75, 890);  
      var html10 = `disagree with any part of the terms then you may not access`
      html10 = html10.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contexttablet3.fillText(html10, 75, 950);     
      var html11 = `the Service.`
      html11 = html11.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contexttablet3.fillText(html11, 75, 1010);   
      var html12 = `Contact Us`
      html12 = html12.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contexttablet3.fillText(html12, 75, 1070);   
      var html13 = `If you have any questions, please contact us.`
      html13 = html13.replace(/Tech Torahs/g, tis.custForm.value.appname)
      tis.contexttablet3.fillText(html13, 75, 1130);     
      imageElem15.src = tis.contexttablet3.canvas.toDataURL();
      tis.android_screenshot_tablet3 = tis.createblobfile(imageElem15.src)
    }

    }
  }
  createblobfile(src){
    var base64data = src.replace("data:image/png;base64,", "");
    var bs = atob(base64data);
    var buffer = new ArrayBuffer(bs.length);
    var ba = new Uint8Array(buffer);
    for (var i = 0; i < bs.length; i++) {
        ba[i] = bs.charCodeAt(i);
    }
    var file = new Blob([ba], { type: "image/png" });
    return file
  }
  defaultandroidicon: any
  defaultiosicon: any
  savebutton(){
  // Icons
    var androidimg: any = document.getElementById('android-upload-media-image')
    var base64data = androidimg.src.replace("data:image/png;base64,", "");
    var bs = atob(base64data);
    var buffer = new ArrayBuffer(bs.length);
    var ba = new Uint8Array(buffer);
    for (var i = 0; i < bs.length; i++) {
        ba[i] = bs.charCodeAt(i);
    }
    var file = new Blob([ba], { type: "image/png" });
    this.defaultandroidicon = file
    this.defaultandroidicon = file

  }

  unassignlecturers = []  
  getlecturers(){
    var form_data = {
      sessionid: this.store.get_token()
    }
    try {
      this.api
        .postmethod("user/unassigned/lecturer", form_data)
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.unassignlecturers = res.data.Items
          }
        })
      } catch (err) {
        this.api.handle_Error(err);
      }
  }

  goBack() {
    window.history.back();
  }
  
    uploadjsonfile(){
      if(this.candownloadapk){
        return
      }
      if(this.despath){
      }
      else{
        return this.api.popover("No path selected");
      }
      var files: any = document.getElementById("upload_wireframe_file");
      var file = files.files[0];
      var filename = file.name;
      // debugger
      var extension = filename.replace(/^.*\./, '');
      if (extension == filename) {
        extension = '';
      } else {
        extension = extension.toLowerCase();
      }
      if($.inArray(extension, ['json']) == -1){
        alert('please valid upload json file');
        this.appConnectionForm.controls['upload_wireframe_file'].setValue("");
        return false;
      }
      if(filename != "google-services.json"){
        return alert('File name must be google-services.json');
      }
      //submit data to API
      // debugger
      this.selected = "upload"
      this.api
        .uploadjson(file, "uploadjson", this.despath)
        .subscribe((event) => {
          console.log(event)
          if (event.status == "success" && event.code == 200) {
            this.downloadapk()
          }
          else{
            this.selected = ""
          }
        });
    }
    selected :any;
    select(item) {
      console.log(item)
      this.selected = item; 
  };
  isActive(item) {
      return this.selected === item;
  };
    onWireframeChange(evt:any){
      let image:any = evt.target.files[0];
      var filename = image.name;
      var extension = filename.replace(/^.*\./, '');
      if (extension == filename) {
        extension = '';
      } else {
        extension = extension.toLowerCase();
      }
      if($.inArray(extension, ['json']) == -1){
        alert('please valid upload json file');
        this.appConnectionForm.controls['upload_wireframe_file'].setValue("");
        return false;
      }
    }

    onChangeicon(evt:any,maxwidth=1024,maxheight=1024, imageid){
      this.percentDone = 100;
      this.uploadSuccess = true;
      let image:any = evt.target.files[0];

      var filename = image.name;
      var extension = filename.replace(/^.*\./, '');

      if (extension == filename) {
        extension = '';
      } else {
        extension = extension.toLowerCase();
      }

    if($.inArray(extension, ['jpg','png','jpeg']) == -1){
        alert('please valid upload image type supoorted');
        $(this).val('');
        return false;
    }
      this.size = image.size;
      let fr = new FileReader();
      fr.onload = () => { // when file has loaded
      var img = new Image();

      var imgwidth = 0;
        var imgheight = 0;
        

      img.onload = () => {
          this.width = img.width;
          this.height = img.height;

          console.log(img.width)
          console.log(img.height)
          if(this.width == maxwidth && this.height == maxheight){
              console.log('its valid file');
              var imggs: any = document.getElementById(imageid)
              imggs.src = img.src
          }else{
            evt.target.value = ''
            alert('Warning: image diemensions beyound required diemensions 1024px X 1024px');
          }
      };

      img.src = fr.result as string; // This is the data URL 
    };

  fr.readAsDataURL(image);
      // this.imgType.nativeElement.value = "";
  }

  clearallforms(){
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    let today_date = dd + '/' + mm + '/' + yyyy;
    this.custForm.reset();
    this.featureControlForm.reset();
    this.iOForm.reset();
    this.androidForm.reset();
    this.appConnectionForm.reset();
    this.desginControlForm.reset();
    this.appConnectionForm.controls['app_lunch_data'].setValue(today_date);
    this.featureControlForm.controls['allow_comments'].setValue('true');
    this.featureControlForm.controls['restrict_content'].setValue('true');
    this.enable_upload_field = false
    this.candownloadapk = false
    this.bundleid = ''
    this.firstTab()
  }
  downloadimage(){
  var file: any = ""
  var ios_screenshot_iphone53: any = document.getElementById("ios_screenshot_ipad3");
    if (ios_screenshot_iphone53.value && ios_screenshot_iphone53.files[0] != null) {
      file = ios_screenshot_iphone53.files[0];
    }
    else{
      file = this.ios_screenshot_iphone53
    }
    this.api
      .uploadimage("image", file, "image-upload", "f202e7725fc796f989b401701e9975dd43d7645e90c99ab9f10ce14f5b97591bd4801f28bca3ad90bb353f8a9c4e8d8203011b5ae12793ba6336059cc7465472")
      .subscribe((event: HttpEvent<any>) => {
        console.log(event);
        var dd: any = event;
        if (dd && dd.body && dd.body.imageUrl) {
      //   debugger
        }
      })
  }
  onChangescreena(evt:any,maxwidth,maxheight, imageid){
    this.percentDone = 100;
    this.uploadSuccess = true;
    let image:any = evt.target.files[0];

    var filename = image.name;
    var extension = filename.replace(/^.*\./, '');

    if (extension == filename) {
      extension = '';
    } else {
      extension = extension.toLowerCase();
    }

  if($.inArray(extension, ['jpg','png','jpeg']) == -1){
      alert('please valid upload image type supoorted');
      $(this).val('');
      return false;
  }
    this.size = image.size;
    let fr = new FileReader();
    fr.onload = () => { // when file has loaded
    var img = new Image();

    var imgwidth = 0;
      var imgheight = 0;
      

    img.onload = () => {
        this.width = img.width;
        this.height = img.height;
        if(this.width >= maxwidth && this.height >= maxheight){
            console.log('its valid file');
            var imggs: any = document.getElementById(imageid)
            imggs.src = img.src
        }else{
          evt.target.value = ''
          alert('Warning: image size '+maxwidth+'px X '+maxheight+'px or greater required');
        }
    };

    img.src = fr.result as string; // This is the data URL 
  };

fr.readAsDataURL(image);
    // this.imgType.nativeElement.value = "";
}
  onChangescreen(evt:any,maxwidth,maxheight, imageid){
    this.percentDone = 100;
    this.uploadSuccess = true;
    let image:any = evt.target.files[0];

    var filename = image.name;
    var extension = filename.replace(/^.*\./, '');

    if (extension == filename) {
      extension = '';
    } else {
      extension = extension.toLowerCase();
    }

  if($.inArray(extension, ['jpg','png','jpeg']) == -1){
      alert('please valid upload image type supoorted');
      $(this).val('');
      return false;
  }
    this.size = image.size;
    let fr = new FileReader();
    fr.onload = () => { // when file has loaded
    var img = new Image();

    var imgwidth = 0;
      var imgheight = 0;
      

    img.onload = () => {
        this.width = img.width;
        this.height = img.height;
        if(this.width == maxwidth && this.height == maxheight){
            console.log('its valid file');
            var imggs: any = document.getElementById(imageid)
            imggs.src = img.src
        }else{
          evt.target.value = ''
          alert('Warning: image size '+maxwidth+'px X '+maxheight+'px required');
        }
    };

    img.src = fr.result as string; // This is the data URL 
  };

fr.readAsDataURL(image);
    // this.imgType.nativeElement.value = "";
}
onChangee(evt:any ,maxwidth ,maxheight , imageid){
  this.percentDone = 100;
  this.uploadSuccess = true;
  let image:any = evt.target.files[0];

  var filename = image.name;
  var extension = filename.replace(/^.*\./, '');

  if (extension == filename) {
    extension = '';
  } else {
    extension = extension.toLowerCase();
  }

if($.inArray(extension, ['jpg','png','jpeg']) == -1){
    alert('please valid upload image type supoorted');
    $(this).val('');
    return false;
}
  this.size = image.size;
  let fr = new FileReader();
  fr.onload = () => { // when file has loaded
  var img = new Image();

  var imgwidth = 0;
    var imgheight = 0;
    

  img.onload = () => {
      this.width = img.width;
      this.height = img.height;

      if(this.width <= maxwidth && this.height <= maxheight){
          console.log('its valid file');
          var imggs: any = document.getElementById(imageid)
          imggs.src = img.src
      }else{
        evt.target.value = ''
        alert('Warning: image diemensions beyound required diemensions 300px X 415px');
      }
  };

  img.src = fr.result as string; // This is the data URL 
};

fr.readAsDataURL(image);
  // this.imgType.nativeElement.value = "";
}

    onChange(evt:any,maxwidth=300,maxheight=414){
      this.percentDone = 100;
      this.uploadSuccess = true;
      let image:any = evt.target.files[0];

      var filename = image.name;
      var extension = filename.replace(/^.*\./, '');

      if (extension == filename) {
        extension = '';
      } else {
        extension = extension.toLowerCase();
      }

    if($.inArray(extension, ['jpg','png','jpeg']) == -1){
        alert('please valid upload image type supoorted');
        $(this).val('');
        return false;
    }
      this.size = image.size;
      let fr = new FileReader();
      fr.onload = () => { // when file has loaded
      var img = new Image();

      var imgwidth = 0;
        var imgheight = 0;
        

      img.onload = () => {
          this.width = img.width;
          this.height = img.height;

          console.log(img.width)
          console.log(img.height)
          if(this.width <= maxwidth && this.height <= maxheight){
              console.log('its valid file');
          }else{
            evt.target.value = ''
            alert('Warning: image diemensions beyound required diemensions 300px X 415px');
          }
      };

      img.src = fr.result as string; // This is the data URL 
    };

  fr.readAsDataURL(image);
      // this.imgType.nativeElement.value = "";
  }

  get f() {
    return this.custForm.controls;
  }

  openCity(evt, cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    

    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  }

  submitAllForm(){
    this.submitted = true;
    if (this.custForm.invalid && this.submitted) {
      alert('Fill all the required in Basic Tab');
      return;
    }
    this.submitted = false;

    this.submittedDesignControl = true;
    if (this.desginControlForm.invalid && this.submittedDesignControl) {
      alert('Fill all the required in Design Control Tab');
      return;
    }
    this.submittedDesignControl = false;

    this.submittedFC = true;
    if (this.featureControlForm.value.allow_comments === null || this.featureControlForm.value.restrict_content === null) {
      alert('Check all the required in Features Control Tab');
      return;
    }
    this.submittedFC = false;

    this.submittediOS = true;
    if (this.iOForm.invalid && this.submittediOS) {
      alert('Check all the required in iOS Tab');
      return;
    }
    this.submittediOS = false;

    this.submittedAndroid = true;
    if (this.androidForm.invalid && this.submittedAndroid) {
      alert('Check all the required in android Tab');
      return;
    }
    this.submittedAndroid = false;
//   debugger
    this.submitPreviewBasic();
  }

  firstTab(){
    var element = $('.tab').find('button.active');
    var element1 = $('.tab').find('button');
    var current_tab = $(element).attr('data-tab_id'); 
    $(element1).first().addClass('active');
    $(element).removeClass('active');
    $('#'+"BASIC").show();
    $('#'+current_tab).hide();
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }

  nextTab(){
    var element = $('.tab').find('button.active');
    var current_tab = $(element).attr('data-tab_id'); 
    var next_tab = $(element).next().attr('data-tab_id');
    
    $(element).next().addClass('active');
    $(element).removeClass('active');
    $('#'+next_tab).show();
    $('#'+current_tab).hide();
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }

  lastTab(){
    var element = $('.tab').find('button.active');
    var current_tab = $(element).attr('data-tab_id'); 
    var next_tab = $(element).prev().attr('data-tab_id');
    
    $(element).prev().addClass('active');
    $(element).removeClass('active');
    $('#'+next_tab).show();
    $('#'+current_tab).hide();
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }

  resetForm(){
    $('form')[0].reset();
  }

  submitPreviewBasic() {
    this.submitted = true;
    console.log(this.custForm)
    if (this.custForm.invalid && this.submitted) {
      return;
    }
    this.submitted = false;
    this.selected = "submit"
    this.submitmediaBasic();
  }

  despath: any
  bundleid: any
  subapp: any
  createappmsg: any
  submitmediaBasic(){
    var mm = "https://shiurimbucket.s3.us-east-2.amazonaws.com/defaults/image.png";
    var lecturer1=""
    if(this.appConnectionForm.value.lecturer && this.unassignlecturers && this.unassignlecturers.length){
      if(this.unassignlecturers.findIndex(e=>e.Lusername.S == this.appConnectionForm.value.lecturer)>-1){
        lecturer1 = this.unassignlecturers[this.unassignlecturers.findIndex(e=>e.Lusername.S == this.appConnectionForm.value.lecturer)].XLusername.S
      }
    }
    
    var form_data = {
      sessionid:this.store.get_token(),
      appname:this.custForm.value.appname,
      about_us:this.custForm.value.about_us,
      welcome:this.custForm.value.welcome,
      term_and_condition:this.custForm.value.term_and_condition,
      contact_email:this.custForm.value.contact_email,
      app_logo_file_choose:mm,
      lecturer: this.appConnectionForm.value.lecturer,
      lecturer1: lecturer1
    };
//   debugger
    try {
      this.api
        .postmethod("customize/create_new_app", form_data)
        .subscribe((res: any) => {
          this.selected = ''
          if (res.status == "success" && res.code == 200) {
            this.createappmsg = res.message
            this.basedestination = res.data.basedestination
            this.bndlname = res.data.bndlname
            this.subapp = res.last_insert_id[0]
            // this.basedestination = res.data.basedestination
            // this.bndlname = res.data.bndlname
            // this.api.popover('Now upload your wireframe json fle in App Connection');
            // this.custForm.reset();
            localStorage.setItem('lusername',res.last_insert_id[0]['lusername']);
            localStorage.setItem('xlusername',res.last_insert_id[0]['xlusername']);
            //Second Tab
            this.desginControlsFormsubmitPreview();
            //Third Tab
            this.updateFeatureControl();
            //Fourth Tab
            this.iOControl();
            //Fifth
            this.androidControl();
            this.bundleid = res.data.bundleid


            var file
            // Update App icon
            var ss: any = document.getElementById("app_logo_file_choose");
            if (ss && ss.value && ss.files[0] != null) {
              file = ss.files[0];
            }
            else{
              file = this.android_app_icon
            }
           
            this.api
            .uploadimage("image", file, "image-upload", this.subapp.lusername)
            .subscribe((event: HttpEvent<any>) => {
              var dd: any = event;
              if (dd && dd.body && dd.body.imageUrl) {
                this.updateappicon(dd.body.imageUrl);
              }
            });
          } 
        });
    } catch (err) {
      this.selected = ''
      this.api.handle_Error(err);
    }
   }
   updateappicon(media){

    var mm = "null";
    if(media){
      mm = media
    }
    
    // debugger
    var form_data = {
      sessionid:this.store.get_token(),
      appname:this.custForm.value.appname,
      about_us:this.custForm.value.about_us,
      welcome:this.custForm.value.welcome,
      term_and_condition:this.custForm.value.term_and_condition,
      contact_email:this.custForm.value.contact_email,
      app_logo_file_choose:mm,
      lusername:this.subapp.lusername,
      xlusername:this.subapp.xlusername,
    };

    try {
      this.api
        .postmethod("update_customize/update_app", form_data)
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
   }
   basedestination: any = "../../lecturerapps/"
   bndlname: any
   downloadedzip:boolean = false;
   downloadedlink:any = "";
   getdownloadablelink(basedestination, bndlname){
     if(bndlname){

     }
     else{
       return this.api.popover("Bundlename required")
     }
    try {
      this.api
        .postmethod("customize/create_zip", {
          sessionid:this.store.get_token(), 
          basedestination: basedestination, 
          bndlname: bndlname
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            this.basedestination = ""
            this.bndlname = ""
            this.downloadedzip = true
            this.downloadedlink = res.downloadlink
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.selected = ''
      this.api.handle_Error(err);
    }
   }
   downloading(url){
     console.log(url)
      saveAs(url);
   }
    downloadapk1(d){
  //   debugger
      setTimeout(()=>{
        window.open(d+"/build/app/outputs/flutter-apk/app-debug.apk", "_external")
      }, 1000)
    }
    apkurl: any
      downloadapk(){
    var form_data = {
      sessionid:this.store.get_token(),
      destination:this.despath,
    };
    try {
      this.api
        .postmethod("customize/downloadapk", form_data)
        .subscribe((res: any) => {
          this.selected = ''
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            var d = res.destination
            d = d.replace("var/www/html/", "http://3.128.52.39/")
            this.apkurl = d
            this.candownloadapk = true
          } else {
            this.api.handle_Error(res);
          }
        },
        err=>{
          this.selected = ''
        }
        );
    } catch (err) {
      this.selected = ''
      this.api.handle_Error(err);
    }
   }
  updateFeatureControl() {
    this.submittedFC = true;
    
    if (this.featureControlForm.invalid && this.submitted) {
      return;
    }

    this.submittedFC = false;

    this.submitFeatureControl();
  }

  submitFeatureControl(){
    // debugger
    var form_data = {
      sessionid:this.store.get_token(),
      allow_comments:this.featureControlForm.value.allow_comments,
      restrict_content:this.featureControlForm.value.restrict_content,
      lusername:localStorage.getItem('lusername'),
      xlusername:localStorage.getItem('xlusername')
    };

    try {
      this.api
        .postmethod("customize/update_feature_control", form_data)
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            //this.api.popover(res.message);
            // this.featureControlForm.reset();
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
   }

  get designControl() {
    return this.desginControlForm.controls;
  }

  desginControlsFormsubmitPreview() {
    this.submittedDesignControl = true;

    if (this.desginControlForm.invalid && this.submittedDesignControl) {
      return;
    }

    this.submittedDesignControl = false;

    var design_control_upload_splash: any = document.getElementById("design_control_upload_splash");
    var design_control_background_image: any = document.getElementById("design_control_background_image");
    var design_control_otp_screen: any = document.getElementById("design_control_otp_screen");
    var design_control_internal_pages: any = document.getElementById("design_control_internal_pages");
    var design_control_internal_pages_background_image: any = document.getElementById("design_control_internal_pages_background_image");


    if (design_control_upload_splash && design_control_upload_splash.value) {
      var files: any = document.getElementById("design_control_upload_splash");
      var file = files.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.subapp.lusername)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
                design_control_upload_splash = dd.body.imageUrl;
                
                var form_data = {
                  sessionid:this.store.get_token(),
                  column_value:design_control_upload_splash,
                  column_name:'design_control_upload_splash',
                  lusername:localStorage.getItem('lusername'),
                  xlusername:localStorage.getItem('xlusername')
                };

                try {
                  this.api
                    .postmethod("customize/update_desgin_controls_images", form_data)
                    .subscribe((res: any) => {
                    });
                } catch (err) {
                  this.api.handle_Error(err);
                }
          }
        });
    }
    else{
      design_control_upload_splash = "https://shiurimbucket.s3.us-east-2.amazonaws.com/defaults/splash-back.jpg";
      var form_data = {
        sessionid:this.store.get_token(),
        column_value:design_control_upload_splash,
        column_name:'design_control_upload_splash',
        lusername:localStorage.getItem('lusername'),
        xlusername:localStorage.getItem('xlusername')
      };

      try {
        this.api
          .postmethod("customize/update_desgin_controls_images", form_data)
          .subscribe((res: any) => {
          });
      } catch (err) {
        this.api.handle_Error(err);
      }
    }

    if (design_control_background_image && design_control_background_image.value) {
      var files: any = document.getElementById("design_control_background_image");
      var file = files.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.subapp.lusername)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            design_control_background_image = dd.body.imageUrl;

            var form_data = {
              sessionid:this.store.get_token(),
              column_value:design_control_background_image,
              column_name:'design_control_background_image',
              lusername:localStorage.getItem('lusername'),
              xlusername:localStorage.getItem('xlusername')
            };

            try {
              this.api
                .postmethod("customize/update_desgin_controls_images", form_data)
                .subscribe((res: any) => {
                });
            } catch (err) {
              this.api.handle_Error(err);
            }

          }
        });
    }
    else{

      design_control_background_image = "https://shiurimbucket.s3.us-east-2.amazonaws.com/defaults/image.jpg";

      var form_data = {
        sessionid:this.store.get_token(),
        column_value:design_control_background_image,
        column_name:'design_control_background_image',
        lusername:localStorage.getItem('lusername'),
        xlusername:localStorage.getItem('xlusername')
      };

      try {
        this.api
          .postmethod("customize/update_desgin_controls_images", form_data)
          .subscribe((res: any) => {
          });
      } catch (err) {
        this.api.handle_Error(err);
      }
    }

    if (design_control_otp_screen && design_control_otp_screen.value) {
      var files: any = document.getElementById("design_control_otp_screen");
      var file = files.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.subapp.lusername)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            design_control_otp_screen = dd.body.imageUrl;

            var form_data = {
              sessionid:this.store.get_token(),
              column_value:design_control_otp_screen,
              column_name:'design_control_otp_screen',
              lusername:localStorage.getItem('lusername'),
              xlusername:localStorage.getItem('xlusername')
            };

            try {
              this.api
                .postmethod("customize/update_desgin_controls_images", form_data)
                .subscribe((res: any) => {
                });
            } catch (err) {
              this.api.handle_Error(err);
            }

          }
        });
    }
    else{

      design_control_otp_screen = "https://shiurimbucket.s3.us-east-2.amazonaws.com/defaults/otp-backscreen.jpg";

      var form_data = {
        sessionid:this.store.get_token(),
        column_value:design_control_otp_screen,
        column_name:'design_control_otp_screen',
        lusername:localStorage.getItem('lusername'),
        xlusername:localStorage.getItem('xlusername')
      };

      try {
        this.api
          .postmethod("customize/update_desgin_controls_images", form_data)
          .subscribe((res: any) => {
          });
      } catch (err) {
        this.api.handle_Error(err);
      }

    }

    if (design_control_internal_pages && design_control_internal_pages.value) {
      var files: any = document.getElementById("design_control_internal_pages");
      var file = files.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.subapp.lusername)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            design_control_internal_pages = dd.body.imageUrl;

            var form_data = {
              sessionid:this.store.get_token(),
              column_value:design_control_internal_pages,
              column_name:'design_control_internal_pages',
              lusername:localStorage.getItem('lusername'),
              xlusername:localStorage.getItem('xlusername')
            };

            try {
              this.api
                .postmethod("customize/update_desgin_controls_images", form_data)
                .subscribe((res: any) => {
                });
            } catch (err) {
              this.api.handle_Error(err);
            }

          }
        });
    }
    else{

      design_control_internal_pages = "https://shiurimbucket.s3.us-east-2.amazonaws.com/defaults/background-download.jpg";

      var form_data = {
        sessionid:this.store.get_token(),
        column_value:design_control_internal_pages,
        column_name:'design_control_internal_pages',
        lusername:localStorage.getItem('lusername'),
        xlusername:localStorage.getItem('xlusername')
      };

      try {
        this.api
          .postmethod("customize/update_desgin_controls_images", form_data)
          .subscribe((res: any) => {
          });
      } catch (err) {
        this.api.handle_Error(err);
      }

    }

    if (design_control_internal_pages_background_image && design_control_internal_pages_background_image.value) {
      var files: any = document.getElementById("design_control_internal_pages_background_image");
      var file = files.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.subapp.lusername)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            design_control_internal_pages_background_image = dd.body.imageUrl;

            var form_data = {
              sessionid:this.store.get_token(),
              column_value:design_control_internal_pages_background_image,
              column_name:'design_control_internal_pages_background_image',
              lusername:localStorage.getItem('lusername'),
              xlusername:localStorage.getItem('xlusername')
            };

            try {
              this.api
                .postmethod("customize/update_desgin_controls_images", form_data)
                .subscribe((res: any) => {
                });
            } catch (err) {
              this.api.handle_Error(err);
            }


          }
        });
    }
    else{

      design_control_internal_pages_background_image = "https://shiurimbucket.s3.us-east-2.amazonaws.com/defaults/background-download.jpg";

      var form_data = {
        sessionid:this.store.get_token(),
        column_value:design_control_internal_pages_background_image,
        column_name:'design_control_internal_pages_background_image',
        lusername:localStorage.getItem('lusername'),
        xlusername:localStorage.getItem('xlusername')
      };

      try {
        this.api
          .postmethod("customize/update_desgin_controls_images", form_data)
          .subscribe((res: any) => {
          });
      } catch (err) {
        this.api.handle_Error(err);
      }

    }

    // debugger
    var form_dataa = {
      sessionid:this.store.get_token(),
      color_combination:this.desginControlForm.value.color_combination,
      color_combination_two:this.desginControlForm.value.color_combination_two,
      select_theme:this.desginControlForm.value.select_theme,
      lusername:localStorage.getItem('lusername'),
      xlusername:localStorage.getItem('xlusername')
    };

    try {
      this.api
        .postmethod("customize/desgin_controls", form_dataa)
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            //this.api.popover(res.message);
            // this.featureControlForm.reset();
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }

  get ios() {
    return this.iOForm.controls;
  }

  iOControl() {
    this.submittediOS = true;
    
    if (this.iOForm.invalid && this.submittediOS) {
      return;
    }

    this.submittediOS = false;
    var ios_app_icon: any = document.getElementById("ios_app_icon");

// Icon Upload
    var file: any = ""
    if (ios_app_icon.value && ios_app_icon.files[0] != null) {
      file = ios_app_icon.files[0];
    }
    else{
      file = this.ios_app_icon
    }
    this.api
      .uploadimage("image", file, "image-upload", this.subapp.lusername)
      .subscribe((event: HttpEvent<any>) => {
        console.log(event);
        var dd: any = event;
        if (dd && dd.body && dd.body.imageUrl) {
          ios_app_icon = dd.body.imageUrl;
          var form_data = {
            sessionid:this.store.get_token(),
            column_value:ios_app_icon,
            column_name:'ios_app_icon',
            lusername:localStorage.getItem('lusername'),
            xlusername:localStorage.getItem('xlusername')
          };
          try {
            this.api
              .postmethod("customize/update_ios_app_images", form_data)
              .subscribe((res: any) => {
              });
          } catch (err) {
            this.api.handle_Error(err);
          }

        }
      });
// Screenshot Upload
// Upload ios_screenshot_iphone51 screenshot
  var file: any = ""
  var ios_screenshot_iphone51: any = document.getElementById("ios_screenshot_iphone51");
    if (ios_screenshot_iphone51.value && ios_screenshot_iphone51.files[0] != null) {
      file = ios_screenshot_iphone51.files[0];
    }
    else{
      file = this.ios_screenshot_iphone51
    }
    this.api
      .uploadimage("image", file, "image-upload", this.subapp.lusername)
      .subscribe((event: HttpEvent<any>) => {
        console.log(event);
        var dd: any = event;
        if (dd && dd.body && dd.body.imageUrl) {
          ios_screenshot_iphone51 = dd.body.imageUrl;
        
// Upload ios_screenshot_iphone52 screenshot
        var ios_screenshot_iphone52: any = document.getElementById("ios_screenshot_iphone52");
        if (ios_screenshot_iphone52.value && ios_screenshot_iphone52.files[0] != null) {
          file = ios_screenshot_iphone52.files[0];
        }
        else{
          file = this.ios_screenshot_iphone52
        }
        this.api
          .uploadimage("image", file, "image-upload", this.subapp.lusername)
          .subscribe((event: HttpEvent<any>) => {
            console.log(event);
            var dd: any = event;
            if (dd && dd.body && dd.body.imageUrl) {
              ios_screenshot_iphone52 = dd.body.imageUrl;
            
// Upload ios_screenshot_iphone53 screenshot
            var ios_screenshot_iphone53: any = document.getElementById("ios_screenshot_iphone53");
            if (ios_screenshot_iphone53.value && ios_screenshot_iphone53.files[0] != null) {
              file = ios_screenshot_iphone53.files[0];
            }
            else{
              file = this.ios_screenshot_iphone53
            }
            this.api
              .uploadimage("image", file, "image-upload", this.subapp.lusername)
              .subscribe((event: HttpEvent<any>) => {
                console.log(event);
                var dd: any = event;
                if (dd && dd.body && dd.body.imageUrl) {
                  ios_screenshot_iphone53 = dd.body.imageUrl;
                
    // Upload ios_screenshot_iphone61 screenshot
                var ios_screenshot_iphone61: any = document.getElementById("ios_screenshot_iphone61");
                if (ios_screenshot_iphone61.value && ios_screenshot_iphone61.files[0] != null) {
                  file = ios_screenshot_iphone61.files[0];
                }
                else{
                  file = this.ios_screenshot_iphone61
                }
                this.api
                  .uploadimage("image", file, "image-upload", this.subapp.lusername)
                  .subscribe((event: HttpEvent<any>) => {
                    console.log(event);
                    var dd: any = event;
                    if (dd && dd.body && dd.body.imageUrl) {
                      ios_screenshot_iphone61 = dd.body.imageUrl;
                    
        // Upload ios_screenshot_iphone62 screenshot
                    var ios_screenshot_iphone62: any = document.getElementById("ios_screenshot_iphone62");
                    if (ios_screenshot_iphone62.value && ios_screenshot_iphone62.files[0] != null) {
                      file = ios_screenshot_iphone62.files[0];
                    }
                    else{
                      file = this.ios_screenshot_iphone62
                    }
                    this.api
                      .uploadimage("image", file, "image-upload", this.subapp.lusername)
                      .subscribe((event: HttpEvent<any>) => {
                        console.log(event);
                        var dd: any = event;
                        if (dd && dd.body && dd.body.imageUrl) {
                          ios_screenshot_iphone62 = dd.body.imageUrl;
                        
            // Upload ios_screenshot_iphone63 screenshot
                        var ios_screenshot_iphone63: any = document.getElementById("ios_screenshot_iphone63");
                        if (ios_screenshot_iphone63.value && ios_screenshot_iphone63.files[0] != null) {
                          file = ios_screenshot_iphone63.files[0];
                        }
                        else{
                          file = this.ios_screenshot_iphone63
                        }
                        this.api
                          .uploadimage("image", file, "image-upload", this.subapp.lusername)
                          .subscribe((event: HttpEvent<any>) => {
                            console.log(event);
                            var dd: any = event;
                            if (dd && dd.body && dd.body.imageUrl) {
                              ios_screenshot_iphone63 = dd.body.imageUrl;
                            
                // Upload ios_screenshot_ipad1 screenshot
                            var ios_screenshot_ipad1: any = document.getElementById("ios_screenshot_ipad1");
                            if (ios_screenshot_ipad1.value && ios_screenshot_ipad1.files[0] != null) {
                              file = ios_screenshot_ipad1.files[0];
                            }
                            else{
                              file = this.ios_screenshot_ipad1
                            }
                            this.api
                              .uploadimage("image", file, "image-upload", this.subapp.lusername)
                              .subscribe((event: HttpEvent<any>) => {
                                console.log(event);
                                var dd: any = event;
                                if (dd && dd.body && dd.body.imageUrl) {
                                  ios_screenshot_ipad1 = dd.body.imageUrl;
                                
                    // Upload ios_screenshot_ipad2 screenshot
                                var ios_screenshot_ipad2: any = document.getElementById("ios_screenshot_ipad2");
                                if (ios_screenshot_ipad2.value && ios_screenshot_ipad2.files[0] != null) {
                                  file = ios_screenshot_ipad2.files[0];
                                }
                                else{
                                  file = this.ios_screenshot_ipad2
                                }
                                this.api
                                  .uploadimage("image", file, "image-upload", this.subapp.lusername)
                                  .subscribe((event: HttpEvent<any>) => {
                                    console.log(event);
                                    var dd: any = event;
                                    if (dd && dd.body && dd.body.imageUrl) {
                                      ios_screenshot_ipad2 = dd.body.imageUrl;
                                    
                        // Upload ios_screenshot_ipad3 screenshot
                                    var ios_screenshot_ipad3: any = document.getElementById("ios_screenshot_ipad3");
                                    if (ios_screenshot_ipad3.value && ios_screenshot_ipad3.files[0] != null) {
                                      file = ios_screenshot_ipad3.files[0];
                                    }
                                    else{
                                      file = this.ios_screenshot_ipad3
                                    }
                                    this.api
                                      .uploadimage("image", file, "image-upload", this.subapp.lusername)
                                      .subscribe((event: HttpEvent<any>) => {
                                        console.log(event);
                                        var dd: any = event;
                                        if (dd && dd.body && dd.body.imageUrl) {
                                          ios_screenshot_ipad3 = dd.body.imageUrl;
                                          // Update all ios screenshot Images
                                        var form_data = {
                                          sessionid:this.store.get_token(),
                                          column_value:ios_screenshot_iphone51,
                                          column_value_iphone51:ios_screenshot_iphone51,
                                          column_value_iphone52:ios_screenshot_iphone52,
                                          column_value_iphone53:ios_screenshot_iphone53,
                                          column_value_iphone61:ios_screenshot_iphone61,
                                          column_value_iphone62:ios_screenshot_iphone62,
                                          column_value_iphone63:ios_screenshot_iphone63,
                                          column_value_ipad1:ios_screenshot_ipad1,
                                          column_value_ipad2:ios_screenshot_ipad2,
                                          column_value_ipad3:ios_screenshot_ipad3,
                                          column_name:'ios_screenshot',
                                          lusername:localStorage.getItem('lusername'),
                                          xlusername:localStorage.getItem('xlusername')
                                        };
                                        try {
                                          this.api
                                            .postmethod("customize/update_ios_app_images", form_data)
                                            .subscribe((res: any) => {
                                              this.api.popover(this.createappmsg)
                                              this.enable_upload_field = true;
                                            });
                                        } catch (err) {
                                          this.api.handle_Error(err);
                                        }
                                      }
                                    });
                                  }
                                });
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });


    // debugger
    var form_data = {
      sessionid:this.store.get_token(),
      lusername:localStorage.getItem('lusername'),
      xlusername:localStorage.getItem('xlusername'),
      ios_app_name:this.iOForm.value.ios_app_name,
      ios_app_subtitle:this.iOForm.value.ios_app_subtitle,
      ios_app_submission_category:this.iOForm.value.ios_app_submission_category,
      ios_app_submission_sub_category:this.iOForm.value.ios_app_submission_sub_category,
      ios_default_language:this.iOForm.value.ios_default_language,
      ios_country_availability:this.iOForm.value.ios_country_availability,
      ios_privacy_policy_page_link:this.iOForm.value.ios_privacy_policy_page_link,
      ios_app_keywords:this.iOForm.value.ios_app_keywords,
      ios_app_description:this.iOForm.value.ios_app_description,
      ios_app_support_url:this.iOForm.value.ios_app_support_url,
      ios_app_marketing_url:this.iOForm.value.ios_app_marketing_url,
      ios_promotion_video_url:this.iOForm.value.ios_promotion_video_url,
      ios_app_version:this.iOForm.value.ios_app_version,
      ios_app_rating:this.iOForm.value.ios_app_rating,
      ios_price:this.iOForm.value.ios_price
    };

    try {
      this.api
        .postmethod("customize/ios_app", form_data)
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            //this.api.popover(res.message);
            // this.iOForm.reset();
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }

  get android() {
    return this.androidForm.controls;
  }

  editAccessLabel(){
    var form_data = {
      sessionid:this.store.get_token()
    };
      
    try {
      this.api
        .postmethod("send_email", form_data)
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }

  androidControl() {
    this.submittedAndroid = true;
    
    if (this.androidForm.invalid && this.submittedAndroid) {
      return;
    }

    this.submittedAndroid = false;
    var file
    var android_app_icon: any = document.getElementById("android_app_icon");
    if (android_app_icon.value && android_app_icon.files[0] != null) {
      file = android_app_icon.files[0];
    }
    else{
      file = this.android_app_icon;
    }
    this.api
      .uploadimage("image", file, "image-upload", this.subapp.lusername)
      .subscribe((event: HttpEvent<any>) => {
        console.log(event);
        var dd: any = event;
        if (dd && dd.body && dd.body.imageUrl) {
          android_app_icon = dd.body.imageUrl;
          var form_data = {
            sessionid:this.store.get_token(),
            column_value:android_app_icon,
            column_name:'android_app_icon',
            lusername:localStorage.getItem('lusername'),
            xlusername:localStorage.getItem('xlusername')
          };
          try {
            this.api
              .postmethod("customize/update_android_app_images", form_data)
              .subscribe((res: any) => {
              });
          } catch (err) {
            this.api.handle_Error(err);
          }

        }
      });
    

    var file: any = ""
// Screenshot for android_screenshot_phone1
    var android_screenshot_phone1: any = document.getElementById("android_screenshot_phone1");
    if (android_screenshot_phone1.value && android_screenshot_phone1.files[0] != null) {
      file = android_screenshot_phone1.files[0];
    }
    else{
      file = this.android_screenshot_phone1;
    }
    this.api
      .uploadimage("image", file, "image-upload", this.subapp.lusername)
      .subscribe((event: HttpEvent<any>) => {
        console.log(event);
        var dd: any = event;
        if (dd && dd.body && dd.body.imageUrl) {
          android_screenshot_phone1 = dd.body.imageUrl;

// Screenshot for android_screenshot_phone2
        var android_screenshot_phone2: any = document.getElementById("android_screenshot_phone2");
        if (android_screenshot_phone2.value && android_screenshot_phone2.files[0] != null) {
          file = android_screenshot_phone2.files[0];
        }
        else{
          file = this.android_screenshot_phone2;
        }
        this.api
          .uploadimage("image", file, "image-upload", this.subapp.lusername)
          .subscribe((event: HttpEvent<any>) => {
            console.log(event);
            var dd: any = event;
            if (dd && dd.body && dd.body.imageUrl) {
              android_screenshot_phone2 = dd.body.imageUrl;
    
        // Screenshot for android_screenshot_phone3
            var android_screenshot_phone3: any = document.getElementById("android_screenshot_phone3");
            if (android_screenshot_phone3.value && android_screenshot_phone3.files[0] != null) {
              file = android_screenshot_phone3.files[0];
            }
            else{
              file = this.android_screenshot_phone3;
            }
            this.api
              .uploadimage("image", file, "image-upload", this.subapp.lusername)
              .subscribe((event: HttpEvent<any>) => {
                console.log(event);
                var dd: any = event;
                if (dd && dd.body && dd.body.imageUrl) {
                  android_screenshot_phone3 = dd.body.imageUrl;

                // Screenshot for android_screenshot_tablet1
                var android_screenshot_tablet1: any = document.getElementById("android_screenshot_tablet1");
                if (android_screenshot_tablet1.value && android_screenshot_tablet1.files[0] != null) {
                  file = android_screenshot_tablet1.files[0];
                }
                else{
                  file = this.android_screenshot_tablet1;
                }
                this.api
                  .uploadimage("image", file, "image-upload", this.subapp.lusername)
                  .subscribe((event: HttpEvent<any>) => {
                    console.log(event);
                    var dd: any = event;
                    if (dd && dd.body && dd.body.imageUrl) {
                      android_screenshot_tablet1 = dd.body.imageUrl;

                    // Screenshot for android_screenshot_tablet2
                    var android_screenshot_tablet2: any = document.getElementById("android_screenshot_tablet2");
                    if (android_screenshot_tablet2.value && android_screenshot_tablet2.files[0] != null) {
                      file = android_screenshot_tablet2.files[0];
                    }
                    else{
                      file = this.android_screenshot_tablet2;
                    }
                    this.api
                      .uploadimage("image", file, "image-upload", this.subapp.lusername)
                      .subscribe((event: HttpEvent<any>) => {
                        console.log(event);
                        var dd: any = event;
                        if (dd && dd.body && dd.body.imageUrl) {
                          android_screenshot_tablet2 = dd.body.imageUrl;

                        // Screenshot for android_screenshot_tablet3
                        var android_screenshot_tablet3: any = document.getElementById("android_screenshot_tablet3");
                        if (android_screenshot_tablet3.value && android_screenshot_tablet3.files[0] != null) {
                          file = android_screenshot_tablet3.files[0];
                        }
                        else{
                          file = this.android_screenshot_tablet3;
                        }
                        this.api
                          .uploadimage("image", file, "image-upload", this.subapp.lusername)
                          .subscribe((event: HttpEvent<any>) => {
                            console.log(event);
                            var dd: any = event;
                            if (dd && dd.body && dd.body.imageUrl) {
                              android_screenshot_tablet3 = dd.body.imageUrl;

                            // Screenshot for android_screenshot_featuredimage
                            var android_screenshot_featuredimage: any = document.getElementById("android_screenshot_featuredimage");
                            if (android_screenshot_featuredimage.value && android_screenshot_featuredimage.files[0] != null) {
                              file = android_screenshot_featuredimage.files[0];
                            }
                            else{
                              file = this.android_screenshot_featuredimage;
                            }
                            this.api
                              .uploadimage("image", file, "image-upload", this.subapp.lusername)
                              .subscribe((event: HttpEvent<any>) => {
                                console.log(event);
                                var dd: any = event;
                                if (dd && dd.body && dd.body.imageUrl) {
                                  android_screenshot_featuredimage = dd.body.imageUrl;

                                // // Update all screenshots 
                                  var form_data = {
                                    sessionid:this.store.get_token(),
                                    column_value:android_screenshot_phone1,
                                    column_value_phone1:android_screenshot_phone1,
                                    column_value_phone2:android_screenshot_phone2,
                                    column_value_phone3:android_screenshot_phone3,
                                    column_value_tablet1:android_screenshot_tablet1,
                                    column_value_tablet2:android_screenshot_tablet2,
                                    column_value_tablet3:android_screenshot_tablet3,
                                    column_value_featuredimage:android_screenshot_featuredimage,
                                    column_name:'android_screenshot',
                                    lusername:localStorage.getItem('lusername'),
                                    xlusername:localStorage.getItem('xlusername')
                                  };

                                  try {
                                    this.api
                                      .postmethod("customize/update_android_app_images", form_data)
                                      .subscribe((res: any) => {
                                      });
                                  } catch (err) {
                                    this.api.handle_Error(err);
                                  }
                                }
                              });
                            }
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });



    // debugger
    var form_data = {
      sessionid:this.store.get_token(),
      lusername:localStorage.getItem('lusername'),
      xlusername:localStorage.getItem('xlusername'),
      android_app_name:this.androidForm.value.android_app_name,
      android_app_subtitle:this.androidForm.value.android_app_subtitle,
      android_app_submission_category:this.androidForm.value.android_app_submission_category,
      android_app_submission_sub_category:this.androidForm.value.android_app_submission_sub_category,
      android_default_language:this.androidForm.value.android_default_language,
      android_country_availability:this.androidForm.value.android_country_availability,
      android_privacy_policy_page_link:this.androidForm.value.android_privacy_policy_page_link,
      android_app_keywords:this.androidForm.value.android_app_keywords,
      android_app_description:this.androidForm.value.android_app_description,
      android_app_support_url:this.androidForm.value.android_app_support_url,
      android_app_marketing_url:this.androidForm.value.android_app_marketing_url,
      android_promotion_video_url:this.androidForm.value.android_promotion_video_url,
      android_app_version:this.androidForm.value.android_app_version,
      android_app_rating:this.androidForm.value.android_app_rating,
      android_price:this.androidForm.value.android_price
    };

    try {
      this.api
        .postmethod("customize/android_app", form_data)
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            //this.api.popover(res.message);
            // this.androidForm.reset();
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
}
