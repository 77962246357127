import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";

@Component({
  selector: 'app-edit-emails-developer',
  templateUrl: './edit-emails-developer.component.html',
  styleUrls: ['./edit-emails-developer.component.scss']
})
export class EditEmailsDeveloperComponent implements OnInit {
  sub: any;
  editlistForm: FormGroup;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private store: StorageService
  ) {}
  apiurl: any;
  ngOnInit() {
    var query: any = this.route.queryParams;
    this.sub = JSON.parse(query.value.data);
    this.gettemplate(this.sub)
    this.editlistForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      cc: [""],
    });
  }
  get f() {
    return this.editlistForm.controls;
  }
  goBack() {
    window.history.back();
  }

  sendLecturerEmail()
  {
    confirm("Send Account Creation Email to Lecturer?");
  }
  gettemplate(template){
    try {
    this.api
    .postmethod("user/email_get/detailtemplate", {
    sessionid: this.store.get_token(),
    template_name: template.template_name,
    template_type: template.template_type,
    })
    .subscribe((res: any) => {
    if (res.status == "success" && res.code == 200 && res.data.Count) {
      if(res.data.Items[0].tempname && res.data.Items[0].tempname.S){
        this.editlistForm.controls['name'].setValue(res.data.Items[0].tempname.S)
      }
      if(res.data.Items[0].mailtext && res.data.Items[0].mailtext.S){
        this.editlistForm.controls['email'].setValue(res.data.Items[0].mailtext.S)
      }
      if(res.data.Items[0].subjecttext && res.data.Items[0].subjecttext.S){
        this.editlistForm.controls['subject'].setValue(res.data.Items[0].subjecttext.S)
      }
    if(res.data.Items[0].ccmails && res.data.Items[0].ccmails.S){
    
    }
    else if(res.data.Items[0].ccmails && res.data.Items[0].ccmails.SS){
    this.editlistForm.controls['cc'].setValue(res.data.Items[0].ccmails.SS.toString())
    }
    } else {
    }
    });
    } catch (err) {
    this.api.handle_Error(err);
    }
    }
    selected :any;
    select(item) {
      console.log(item)
      this.selected = item; 
  };
  isActive(item) {
      return this.selected === item;
  };
  model: any;
  onSubmit() {
    this.submitted = true;
    if (this.editlistForm.invalid) {
      return;
    }
    var arr = []
    if(this.editlistForm.value.cc){
      var aar = this.editlistForm.value.cc
      aar = aar.split(",")
      aar.forEach((data, index)=>{
        arr.push(data.trim())
      })
    }
    else{
      arr = null
    }
//   debugger;
    this.selected = 'submit'; 
    try {
      this.api
        .postmethod("user/email_save/save"+this.sub.template_type, {
          sessionid: this.store.get_token(),
          name: this.editlistForm.value.name,
          email: this.editlistForm.value.email,
          subject: this.editlistForm.value.subject,
          cc: arr,
          template_name: this.sub.template_name,
          template_type: this.sub.template_type
        })
        .subscribe((res: any) => {
          this.selected = ''; 
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            this.router.navigate(["/listemails"]);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.selected = ''; 
      this.api.handle_Error(err);
    }
  }

  resetPreview() {
    this.router.navigate(["/listemails"]);
  }
}