import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";

@Component({
  selector: 'app-change-lecturer-password',
  templateUrl: './change-lecturer-password.component.html',
  styleUrls: ['./change-lecturer-password.component.css']
})
export class ChangeLecturerPasswordComponent implements OnInit {
  changeForm: FormGroup;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private store: StorageService,
    private router: Router,
    private route: ActivatedRoute)
  { }
  sub: any
  ngOnInit(): void {
    var query: any = this.route.queryParams;
    this.sub = JSON.parse(query.value.data);
    this.changeForm = this.formBuilder.group({
      opassword: ["", [Validators.required, Validators.minLength(6)]],
      password: ["", [Validators.required, Validators.minLength(6)]],
      cpassword: ["", [Validators.required, Validators.minLength(6)]],
    },{validator: this.checkIfMatchingPasswords('password', 'cpassword')});
  }
  get f() {
    return this.changeForm.controls;
  }

    goBack() {
    window.history.back();
  }
    resetPreview() {
    //alert('hii');
    this.router.navigate(["/listaccounts"]);
  }
  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
          passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({notEquivalent: true})
      }
      else {
          return passwordConfirmationInput.setErrors(null);
      }
    }
  }

  submitPreview(){
    this.submitted = true;
    if (this.changeForm.invalid) {
      return;
    }
    try {
      this.api
        .postmethod("user/changepassword/lecturer", {
          lusername: this.sub.Lusername.S,
          xlusername: this.sub.XLusername.S,
          opassword: this.changeForm.value.opassword,
          password: this.changeForm.value.password,
          timestamp: Date.now(),
          created: Date.now(),
          sessionid: this.store.get_token()
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            this.router.navigate(["/listaccounts"]);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
}
