import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBydate'
})
export class OrderBydatePipe implements PipeTransform {

  transform(value: any[]): any[] {
      return value.sort((a: any, b: any) => b.created.N.localeCompare(a.created.N));
  }

}
