import { Component, OnInit,VERSION ,ViewChild,ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import * as $ from 'jquery';
import { HttpEvent, HttpEventType } from "@angular/common/http";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";
import { saveAs } from 'file-saver';

@Component({
  selector: "kt-edit-app-account",
  templateUrl: "./edit-app-account.component.html",
  styleUrls: ["./edit-app-account.component.css"]
})
export class EditAppAccountComponent implements OnInit {
  submitted = false;
  submittedFC = false;
  submittedDesignControl = false;
  submittediOS = false;
  submittedAndroid = false;
  custForm: FormGroup;
  desginControlForm: FormGroup;
  featureControlForm: FormGroup;
  iOForm: FormGroup;
  androidForm: FormGroup;
  appConnectionForm: FormGroup;
  sub: any;
  update_Lusername: any;
  update_XLusername: any;
  percentDone: number;
  uploadSuccess: boolean;
  size:any;
  width:number;
  height:number;

  @ViewChild('coverFilesInput') imgType:ElementRef;
  version = VERSION;

  constructor(
    private api: ApiService,
    private store: StorageService,
  	private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
  	) { 
    var query: any = this.route.queryParams;
    // this.sub = JSON.parse(query.value.data);
    this.update_XLusername = JSON.parse(query.value.data).XLusername;
    this.update_Lusername = JSON.parse(query.value.data).Lusername;
    this.getApps(this.update_Lusername.S)
    }
 
    
    getApps(Lusername) {
      if(Lusername){
        try {
          this.api
            .postmethod("user/singleapp/application", {
              sessionid: this.store.get_token(),
              Lusername: Lusername
            })
            .subscribe((res: any) => {
              if (res.status == "success" && res.code == 200) {
                if(res.data.Items[0] && res.data.Items[0].XLusername && res.data.Items[0].XLusername.S){
                  this.sub = res.data.Items[0]
                  console.log(this.sub)
                  this.reassignvalues();
                }
              } else {
                this.api.handle_Error(res);
              }
            });
        } catch (err) {
          this.api.handle_Error(err);
        }
      }
    }
  ngOnInit(): void {
    this.getlecturers()
  	this.custForm = this.formBuilder.group({
      appname: ["", [Validators.required]],
      about_us: ["", [Validators.required]],
      welcome: ["", [Validators.required]],
      term_and_condition: ["", [Validators.required]],
      contact_email: ["", [Validators.required]],
      app_logo_file_choose: [""]
    });

    this.featureControlForm = this.formBuilder.group({
      allow_comments:[null,""],
      restrict_content:[null,""]
    });

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    let today_date = dd + '/' + mm + '/' + yyyy;
    
    this.appConnectionForm = this.formBuilder.group({
      app_lunch_data:[today_date],
      lecturer:"",
    });       

    this.desginControlForm = this.formBuilder.group({
      select_theme:["", [Validators.required]],
      color_combination:["", [Validators.required]],
      color_combination_two:["", [Validators.required]],    
      design_control_upload_splash: [""],
      design_control_background_image:[""],
      design_control_otp_screen:[""],
      design_control_internal_pages:[""],
      design_control_internal_pages_background_image:[""]
    }); 

    //for iOS form
    this.iOForm = this.formBuilder.group({
      ios_app_name: ["", [Validators.required]],
      ios_app_subtitle: ["", [Validators.required]],
      ios_app_submission_category: ["", [Validators.required]],
      ios_app_submission_sub_category: ["", [Validators.required]],
      ios_default_language: ["", [Validators.required]],
      ios_country_availability: ["", [Validators.required]],
      ios_privacy_policy_page_link: ["", [Validators.required]],
      ios_app_keywords: ["", [Validators.required]],
      ios_app_description: ["", [Validators.required]],
      ios_app_support_url: ["", [Validators.required]],
      ios_app_marketing_url: ["", [Validators.required]],
      ios_app_icon: [""],
      ios_screenshot_iphone51: [""],
      ios_screenshot_iphone52: [""],
      ios_screenshot_iphone53: [""],
      ios_screenshot_iphone61: [""],
      ios_screenshot_iphone62: [""],
      ios_screenshot_iphone63: [""],
      ios_screenshot_ipad1: [""],
      ios_screenshot_ipad2: [""],
      ios_screenshot_ipad3: [""],
      ios_promotion_video_url: [""],
      ios_app_version: ["", [Validators.required]],
      ios_app_rating: ["", [Validators.required]],
      ios_price: ["", [Validators.required]]
    });  

    //for Android form
    this.androidForm = this.formBuilder.group({
      android_app_name: ["", [Validators.required]],
      android_app_subtitle: ["", [Validators.required]],
      android_app_submission_category: ["", [Validators.required]],
      android_app_submission_sub_category: ["", [Validators.required]],
      android_default_language: ["", [Validators.required]],
      android_country_availability: ["", [Validators.required]],
      android_privacy_policy_page_link: ["", [Validators.required]],
      android_app_keywords: ["", [Validators.required]],
      android_app_description: ["", [Validators.required]],
      android_app_support_url: ["", [Validators.required]],
      android_app_marketing_url: ["", [Validators.required]],
      android_app_icon: [""],
      android_screenshot_phone1: [""],
      android_screenshot_phone2: [""],
      android_screenshot_phone3: [""],
      android_screenshot_tablet1: [""],
      android_screenshot_tablet2: [""],
      android_screenshot_tablet3: [""],
      android_screenshot_featuredimage: [""],
      android_promotion_video_url: [""],
      android_app_version: ["", [Validators.required]],
      android_app_rating: ["", [Validators.required]],
      android_price: ["", [Validators.required]]
    });
    
  }
  unassignlecturers = []
  getlecturers(){
    var form_data = {
      sessionid: this.store.get_token()
    }
    try {
      this.api
        .postmethod("user/all/lecturer", form_data)
        // .postmethod("user/unassigned/lecturer", form_data)
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.unassignlecturers = res.data.Items
          }
        })
      } catch (err) {
        this.api.handle_Error(err);
      }
  }
  
  applogo: any;
  ios_icon: any;
  ios_screenshot_ipad1: any;
  ios_screenshot_ipad2: any;
  ios_screenshot_ipad3: any;
  ios_screenshot_iphone51: any;
  ios_screenshot_iphone52: any;
  ios_screenshot_iphone53: any;
  ios_screenshot_iphone61: any;
  ios_screenshot_iphone62: any;
  ios_screenshot_iphone63: any;
  android_icon: any;
  android_screenshot_phone1: any;
  android_screenshot_phone2: any;
  android_screenshot_phone3: any;
  design_control_background: any;
  android_screenshot_tablet1: any;
  android_screenshot_tablet2: any;
  android_screenshot_tablet3: any;
  android_screenshot_featuredimage: any;
  design_control_upload: any;
  design_control_otp: any;
  design_control_internal: any;
  design_control_internal_pages_background: any;
  reassignvalues(){
    if(this.sub.appname){
      this.custForm.controls['appname'].setValue(this.sub.appname.S);
    }
    if(this.sub.about_us){
      this.custForm.controls['about_us'].setValue(this.sub.about_us.S);
    }
    if(this.sub.welcome){
      this.custForm.controls['welcome'].setValue(this.sub.welcome.S);
    }
    if(this.sub.contact_email){
      this.custForm.controls['contact_email'].setValue(this.sub.contact_email.S);
    }
    if(this.sub.term_and_condition){
      this.custForm.controls['term_and_condition'].setValue(this.sub.term_and_condition.S);
    }
    if(this.sub.app_logo){
      this.applogo = this.sub.app_logo.S
    }
    // this.custForm.controls['app_logo_file_choose'].setValue(this.sub.app_logo_file_choose.S);
    //console.log('this.sub', this.sub);

    if(this.sub.allow_comments){
      this.featureControlForm.controls['allow_comments'].setValue(this.sub.allow_comments.S);
    }
    if(this.sub.restrict_content){
      this.featureControlForm.controls['restrict_content'].setValue(this.sub.restrict_content.S);
    }
    // this.appConnectionForm.controls['app_lunch_data'].setValue(this.sub.app_lunch_data.S);
    if(this.sub.select_theme){
      this.desginControlForm.controls['select_theme'].setValue(this.sub.select_theme.S);
    }
    if(this.sub.color_combination){
      this.desginControlForm.controls['color_combination'].setValue(this.sub.color_combination.S);
    }
    if(this.sub.color_combination_two){
      this.desginControlForm.controls['color_combination_two'].setValue(this.sub.color_combination_two.S);
    }
    // this.desginControlForm.controls['design_control_upload_splash'].setValue(this.sub.design_control_upload_splash.S);
    // this.desginControlForm.controls['design_control_background_image'].setValue(this.sub.design_control_background_image.S);
    // this.desginControlForm.controls['design_control_otp_screen'].setValue(this.sub.design_control_otp_screen.S);
    // this.desginControlForm.controls['design_control_internal_pages'].setValue(this.sub.design_control_internal_pages.S);
    // this.desginControlForm.controls['design_control_internal_pages_background_image'].setValue(this.sub.design_control_internal_pages_background_image.S);
    if(this.sub.design_control_background_image){
      this.design_control_background = this.sub.design_control_background_image.S
    }
    if(this.sub.design_control_upload_splash){
      this.design_control_upload = this.sub.design_control_upload_splash.S
    }
    if(this.sub.design_control_otp_screen){
      this.design_control_otp = this.sub.design_control_otp_screen.S
    }
    if(this.sub.design_control_internal_pages){
      this.design_control_internal = this.sub.design_control_internal_pages.S
    }
    if(this.sub.design_control_internal_pages_background_image){
      this.design_control_internal_pages_background = this.sub.design_control_internal_pages_background_image.S
    }
    if(this.sub.ios_app_name){
      this.iOForm.controls['ios_app_name'].setValue(this.sub.ios_app_name.S);
    }
    if(this.sub.ios_app_subtitle){
      this.iOForm.controls['ios_app_subtitle'].setValue(this.sub.ios_app_subtitle.S);
    }
    if(this.sub.ios_app_submission_category){
      this.iOForm.controls['ios_app_submission_category'].setValue(this.sub.ios_app_submission_category.S);
    }
    if(this.sub.ios_app_submission_category){
      this.iOForm.controls['ios_app_submission_category'].setValue(this.sub.ios_app_submission_category.S);
    }
    if(this.sub.ios_app_submission_sub_category){
      this.iOForm.controls['ios_app_submission_sub_category'].setValue(this.sub.ios_app_submission_sub_category.S);
    }
    if(this.sub.ios_default_language){
      this.iOForm.controls['ios_default_language'].setValue(this.sub.ios_default_language.S);
    }
    if(this.sub.ios_country_availability){
      this.iOForm.controls['ios_country_availability'].setValue(this.sub.ios_country_availability.S);
    }
    if(this.sub.ios_privacy_policy_page_link){
      this.iOForm.controls['ios_privacy_policy_page_link'].setValue(this.sub.ios_privacy_policy_page_link.S);
    }
    if(this.sub.ios_app_keywords){
      this.iOForm.controls['ios_app_keywords'].setValue(this.sub.ios_app_keywords.S);
    }
    if(this.sub.ios_app_description){
      this.iOForm.controls['ios_app_description'].setValue(this.sub.ios_app_description.S);
    }
    if(this.sub.ios_app_support_url){
      this.iOForm.controls['ios_app_support_url'].setValue(this.sub.ios_app_support_url.S);
    }
    if(this.sub.ios_app_marketing_url){
      this.iOForm.controls['ios_app_marketing_url'].setValue(this.sub.ios_app_marketing_url.S);
    }
    if(this.sub.ios_app_icon){
      this.ios_icon = this.sub.ios_app_icon.S;
    }
    if(this.sub.ios_screenshot_ipad1){
      this.ios_screenshot_ipad1 = this.sub.ios_screenshot_ipad1.S;
    }
    if(this.sub.ios_screenshot_ipad2){
      this.ios_screenshot_ipad2 = this.sub.ios_screenshot_ipad2.S;
    }
    if(this.sub.ios_screenshot_ipad3){
      this.ios_screenshot_ipad3 = this.sub.ios_screenshot_ipad3.S;
    }
    if(this.sub.ios_screenshot_iphone51){
      this.ios_screenshot_iphone51 = this.sub.ios_screenshot_iphone51.S;
    }
    if(this.sub.ios_screenshot_iphone52){
      this.ios_screenshot_iphone52 = this.sub.ios_screenshot_iphone52.S;
    }
    if(this.sub.ios_screenshot_iphone53){
      this.ios_screenshot_iphone53 = this.sub.ios_screenshot_iphone53.S;
    }
    if(this.sub.ios_screenshot_iphone61){
      this.ios_screenshot_iphone61 = this.sub.ios_screenshot_iphone61.S;
    }
    if(this.sub.ios_screenshot_iphone62){
      this.ios_screenshot_iphone62 = this.sub.ios_screenshot_iphone62.S;
    }
    if(this.sub.ios_screenshot_iphone63){
      this.ios_screenshot_iphone63 = this.sub.ios_screenshot_iphone63.S;
    }
    //this.iOForm.controls['ios_app_icon'].setValue(this.sub.ios_app_icon.S);
    //this.iOForm.controls['ios_screenshot'].setValue(this.sub.ios_screenshot.S);
    if(this.sub.ios_promotion_video_url){
      this.iOForm.controls['ios_promotion_video_url'].setValue(this.sub.ios_promotion_video_url.S);
    }
    if(this.sub.ios_app_version){
      this.iOForm.controls['ios_app_version'].setValue(this.sub.ios_app_version.S);
    }
    if(this.sub.ios_app_rating){
      this.iOForm.controls['ios_app_rating'].setValue(this.sub.ios_app_rating.S);
    }
    if(this.sub.ios_price){
      this.iOForm.controls['ios_price'].setValue(this.sub.ios_price.S);
    }
    if(this.sub.android_app_name){
      this.androidForm.controls['android_app_name'].setValue(this.sub.android_app_name.S);
    }
    if(this.sub.android_app_subtitle){
      this.androidForm.controls['android_app_subtitle'].setValue(this.sub.android_app_subtitle.S);
    }
    if(this.sub.android_app_submission_category){
      this.androidForm.controls['android_app_submission_category'].setValue(this.sub.android_app_submission_category.S);
    }
    if(this.sub.android_app_submission_sub_category){
      this.androidForm.controls['android_app_submission_sub_category'].setValue(this.sub.android_app_submission_sub_category.S);
    }
    if(this.sub.android_default_language){
      this.androidForm.controls['android_default_language'].setValue(this.sub.android_default_language.S);
    }
    if(this.sub.android_country_availability){
      this.androidForm.controls['android_country_availability'].setValue(this.sub.android_country_availability.S);
    }
    if(this.sub.android_privacy_policy_page_link){
      this.androidForm.controls['android_privacy_policy_page_link'].setValue(this.sub.android_privacy_policy_page_link.S);
    }
    if(this.sub.android_app_keywords){
      this.androidForm.controls['android_app_keywords'].setValue(this.sub.android_app_keywords.S);
    }
    if(this.sub.android_app_description){
      this.androidForm.controls['android_app_description'].setValue(this.sub.android_app_description.S);
    }
    if(this.sub.android_app_support_url){
      this.androidForm.controls['android_app_support_url'].setValue(this.sub.android_app_support_url.S);
    }
    if(this.sub.android_app_marketing_url){
      this.androidForm.controls['android_app_marketing_url'].setValue(this.sub.android_app_marketing_url.S);
    }
    
    //this.androidForm.controls['android_app_icon'].setValue(this.sub.android_app_icon.S);
    //this.androidForm.controls['android_screenshot'].setValue(this.sub.android_screenshot.S);
    if(this.sub.android_app_icon){
      this.android_icon = this.sub.android_app_icon.S;
    }
    if(this.sub.android_screenshot_phone1){
      this.android_screenshot_phone1 = this.sub.android_screenshot_phone1.S;
    }
    if(this.sub.android_screenshot_phone2){
      this.android_screenshot_phone2 = this.sub.android_screenshot_phone2.S;
    }
    if(this.sub.android_screenshot_phone3){
      this.android_screenshot_phone3 = this.sub.android_screenshot_phone3.S;
    }
    if(this.sub.android_screenshot_tablet1){
      this.android_screenshot_tablet1 = this.sub.android_screenshot_tablet1.S;
    }
    if(this.sub.android_screenshot_tablet2){
      this.android_screenshot_tablet2 = this.sub.android_screenshot_tablet2.S;
    }
    if(this.sub.android_screenshot_tablet3){
      this.android_screenshot_tablet3 = this.sub.android_screenshot_tablet3.S;
    }
    if(this.sub.android_screenshot_featuredimage){
      this.android_screenshot_featuredimage = this.sub.android_screenshot_featuredimage.S;
    }
    if(this.sub.android_promotion_video_url){
      this.androidForm.controls['android_promotion_video_url'].setValue(this.sub.android_promotion_video_url.S);
    }
    if(this.sub.android_app_version){
      this.androidForm.controls['android_app_version'].setValue(this.sub.android_app_version.S);
    }
    if(this.sub.android_app_rating){
      this.androidForm.controls['android_app_rating'].setValue(this.sub.android_app_rating.S);
    }
    if(this.sub.android_price){
      this.androidForm.controls['android_price'].setValue(this.sub.android_price.S);
    }
    if(this.sub.lecturers && this.sub.lecturers.SS && this.sub.lecturers.SS.length){
      var tis = this
      // this.sub.lecturers.SS.forEach((data, index)=>{
      //   console.log(tis.appConnectionForm.controls["lecturer"])
      //   console.log(tis.appConnectionForm.controls["lecturer"]["controls"])
      //   // debugger
      // })
  //   debugger
      tis.appConnectionForm.controls["lecturer"].setValue(this.sub.lecturers.SS[0])
    }
  }
  changemultiplelect(){
    // debugger
  }
      onChange(evt:any,maxwidth=300,maxheight=414){
      this.percentDone = 100;
      this.uploadSuccess = true;
      let image:any = evt.target.files[0];

      var filename = image.name;
      var extension = filename.replace(/^.*\./, '');

      if (extension == filename) {
        extension = '';
      } else {
        extension = extension.toLowerCase();
      }

      if($.inArray(extension, ['jpg','png','jpeg']) == -1){
          alert('please valid upload image type supoorted');
          return false;
      }



      this.size = image.size;
      let fr = new FileReader();
      fr.onload = () => { // when file has loaded
      var img = new Image();

      var imgwidth = 0;
      var imgheight = 0;

      img.onload = () => {
          this.width = img.width;
          this.height = img.height;

          if(this.width <= maxwidth && this.height <= maxheight){
              console.log('its valid file');
          }else{
            evt.target.value = ''
            alert('Warning: image dimensions beyound required dimensions 300px X 415px');
          }
      };

      img.src = fr.result as string; // This is the data URL 
    };

  fr.readAsDataURL(image);
      //this.imgType.nativeElement.value = "";
  }
  onChangee(evt:any,maxwidth,maxheight, imageid){
    this.percentDone = 100;
    this.uploadSuccess = true;
    let image:any = evt.target.files[0];
  
    var filename = image.name;
    var extension = filename.replace(/^.*\./, '');
  
    if (extension == filename) {
      extension = '';
    } else {
      extension = extension.toLowerCase();
    }
  
  if($.inArray(extension, ['jpg','png','jpeg']) == -1){
      alert('please valid upload image type supoorted');
      $(this).val('');
      return false;
  }
    this.size = image.size;
    let fr = new FileReader();
    fr.onload = () => { // when file has loaded
    var img = new Image();
  
    var imgwidth = 0;
      var imgheight = 0;
      
  
    img.onload = () => {
        this.width = img.width;
        this.height = img.height;
  
        console.log(img.width)
        console.log(img.height)
        if(this.width <= maxwidth && this.height <= maxheight){
            console.log('its valid file');
            var imggs: any = document.getElementById(imageid)
            imggs.src = img.src
        }else{
          evt.target.value = ''
          alert('Warning: image diemensions beyound required diemensions 300px X 415px');
        }
    };
  
    img.src = fr.result as string; // This is the data URL 
  };
  
  fr.readAsDataURL(image);
    // this.imgType.nativeElement.value = "";
  }
  onChangeicon(evt:any,maxwidth,maxheight, imageid){
  this.percentDone = 100;
  this.uploadSuccess = true;
  let image:any = evt.target.files[0];

  var filename = image.name;
  var extension = filename.replace(/^.*\./, '');

  if (extension == filename) {
    extension = '';
  } else {
    extension = extension.toLowerCase();
  }

  if($.inArray(extension, ['jpg','png','jpeg']) == -1){
      alert('please valid upload image type supoorted');
      return false;
  }



  this.size = image.size;
  let fr = new FileReader();
  fr.onload = () => { // when file has loaded
  var img = new Image();

  var imgwidth = 0;
  var imgheight = 0;

  img.onload = () => {
      this.width = img.width;
      this.height = img.height;

      if(this.width == maxwidth && this.height == maxheight){
          console.log('its valid file');
          var imggs: any = document.getElementById(imageid)
          imggs.src = img.src
      }else{
        evt.target.value = ''
        alert('Warning: image diemensions beyound required diemensions 1024px X 1024px');
      }
  };

  img.src = fr.result as string; // This is the data URL 
};

fr.readAsDataURL(image);
  //this.imgType.nativeElement.value = "";
}

onChangescreena(evt:any,maxwidth,maxheight, imageid){
  this.percentDone = 100;
  this.uploadSuccess = true;
  let image:any = evt.target.files[0];

  var filename = image.name;
  var extension = filename.replace(/^.*\./, '');

  if (extension == filename) {
    extension = '';
  } else {
    extension = extension.toLowerCase();
  }

if($.inArray(extension, ['jpg','png','jpeg']) == -1){
    alert('please valid upload image type supoorted');
    $(this).val('');
    return false;
}
  this.size = image.size;
  let fr = new FileReader();
  fr.onload = () => { // when file has loaded
  var img = new Image();

  var imgwidth = 0;
    var imgheight = 0;
    

  img.onload = () => {
      this.width = img.width;
      this.height = img.height;
      if(this.width >= maxwidth && this.height >= maxheight){
          console.log('its valid file');
          var imggs: any = document.getElementById(imageid)
          imggs.src = img.src
      }else{
        evt.target.value = ''
        alert('Warning: image size '+maxwidth+'px X '+maxheight+'px or greater required');
      }
  };

  img.src = fr.result as string; // This is the data URL 
};

fr.readAsDataURL(image);
  // this.imgType.nativeElement.value = "";
}
  onChangescreen(evt:any,maxwidth,maxheight, imageid){
  this.percentDone = 100;
  this.uploadSuccess = true;
  let image:any = evt.target.files[0];

  var filename = image.name;
  var extension = filename.replace(/^.*\./, '');

  if (extension == filename) {
    extension = '';
  } else {
    extension = extension.toLowerCase();
  }

  if($.inArray(extension, ['jpg','png','jpeg']) == -1){
      alert('please valid upload image type supoorted');
      return false;
  }



  this.size = image.size;
  let fr = new FileReader();
  fr.onload = () => { // when file has loaded
  var img = new Image();

  var imgwidth = 0;
  var imgheight = 0;

  img.onload = () => {
      this.width = img.width;
      this.height = img.height;

      if(this.width == maxwidth && this.height == maxheight){
          console.log('its valid file');
          var imggs: any = document.getElementById(imageid)
          imggs.src = img.src
      }else{
        evt.target.value = ''
        alert('Warning: image size '+maxwidth+'px X '+maxheight+'px required');
      }
  };

  img.src = fr.result as string; // This is the data URL 
};

fr.readAsDataURL(image);
  //this.imgType.nativeElement.value = "";
}

  get f() {
    return this.custForm.controls;
  }

  resetPreview(){

  }

  editAccessLabel(){
    
  }

  openCity(evt, cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    

    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  }


  nextTab(){
    var element = $('.tab').find('button.active');
    var current_tab = $(element).attr('data-tab_id'); 
    var next_tab = $(element).next().attr('data-tab_id');
    
    $(element).next().addClass('active');
    $(element).removeClass('active');
    $('#'+next_tab).show();
    $('#'+current_tab).hide();
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }

  lastTab(){
    var element = $('.tab').find('button.active');
    var current_tab = $(element).attr('data-tab_id'); 
    var next_tab = $(element).prev().attr('data-tab_id');
    
    $(element).prev().addClass('active');
    $(element).removeClass('active');
    $('#'+next_tab).show();
    $('#'+current_tab).hide();
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }

  submitAllForm(){
    this.submitted = true;
    if (this.custForm.invalid && this.submitted) {
      alert('Fill all the required in Basic Tab');
      return;
    }
    this.submitted = false;

    this.submittedDesignControl = true;
    if (this.desginControlForm.invalid && this.submittedDesignControl) {
      alert('Fill all the required in Design Control Tab');
      return;
    }
    this.submittedDesignControl = false;

    this.submittedFC = true;
    if (this.featureControlForm.value.allow_comments === null || this.featureControlForm.value.restrict_content === null) {
      alert('Check all the required in Features Control Tab');
      return;
    }
    this.submittedFC = false;

    this.submittediOS = true;
    if (this.iOForm.invalid && this.submittediOS) {
      alert('Check all the required in iOS Tab');
      return;
    }
    this.submittediOS = false;

    this.submittedAndroid = true;
    if (this.androidForm.invalid && this.submittedAndroid) {
      alert('Check all the required in android Tab');
      return;
    }
    this.submittedAndroid = false;
    this.submitPreviewBasic(false);
    this.desginControlsFormsubmitPreview(false);
    this.updateFeatureControl(false);
    this.iOControl(false);        
    this.androidControl(false);
  }

  submitPreviewBasic(popup) {
    this.submitted = true;
    
    if (this.custForm.invalid && this.submitted) {
      return;
  	}

    this.submitted = false;

    var ss: any = document.getElementById("app_logo_file_choose");

    if (ss && ss.value) {
      var files: any = document.getElementById("app_logo_file_choose");
      var file = files.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            this.submitmediaBasic(dd.body.imageUrl, popup);
          }
        });
    } else {
      this.submitmediaBasic(this.applogo, popup);
    }
  }

  urlExists(url) {
    return fetch(url, {mode: "no-cors"})
      .then(res => {
         console.log(res)
        })
      .catch(err => {
        console.log(err)
      })
  }
  downloadsource(){
    var form_data = {
      sessionid: this.store.get_token(),
      basedestination: this.sub.destinationpath.S
    };

    try {
      this.api
        .postmethod("customize/checkfileexist", form_data)
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            var bdlname = this.sub.bundleid.S.split(".")
            if(this.sub.destinationpath.S){
              var destinationpath = this.sub.destinationpath.S
              destinationpath = destinationpath.replace("../../", "http://api.shiur.cloud/")
              destinationpath = destinationpath+".zip"
              console.log(destinationpath)
              saveAs(destinationpath);
            }
          }
        })
      }
      catch(e){

      }
  }

  submitmediaBasic(media="null", popup){
    var mm = "null";
    if(media){
      mm = media
    }

    // debugger
    var form_data = {
      sessionid:this.store.get_token(),
      appname:this.custForm.value.appname,
      about_us:this.custForm.value.about_us,
      welcome:this.custForm.value.welcome,
      term_and_condition:this.custForm.value.term_and_condition,
      contact_email:this.custForm.value.contact_email,
      app_logo_file_choose:mm,
      lusername:this.sub.Lusername.S,
      xlusername:this.sub.XLusername.S,
    };

    try {
      this.api
        .postmethod("update_customize/update_app", form_data)
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            if(popup){
              this.api.popover(res.message);
            }
            //this.custForm.reset();
            //console.log(res,'res');

            //localStorage.setItem('lusername',res.last_insert_id[0]['lusername']);
            //localStorage.setItem('xlusername',res.last_insert_id[0]['xlusername'])
            this.getApps(this.update_Lusername)
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
   }

  updateFeatureControl(popup) {
    this.submittedFC = true;
    
    if (this.featureControlForm.invalid && this.submitted) {
      return;
    }

    this.submittedFC = false;

    this.submitFeatureControl(popup);
  }

  submitFeatureControl(popup){
    // debugger
    var form_data = {
      sessionid:this.store.get_token(),
      allow_comments:this.featureControlForm.value.allow_comments,
      restrict_content:this.featureControlForm.value.restrict_content,
      lusername:this.sub.Lusername.S,
      xlusername:this.sub.XLusername.S
    };

    try {
      this.api
        .postmethod("customize/update_feature_control", form_data)
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            if(popup){
              this.api.popover(res.message);
            }
            //this.featureControlForm.reset();
            this.getApps(this.update_Lusername)
          } else {
            //this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
   }

  get designControl() {
    return this.desginControlForm.controls;
  }

  goBack() {
    window.history.back();
  }
  
  desginControlsFormsubmitPreview(popup) {
    this.submittedDesignControl = true;

    if (this.desginControlForm.invalid && this.submittedDesignControl) {
      return;
    }

    this.submittedDesignControl = false;

    var design_control_upload_splash: any = document.getElementById("design_control_upload_splash");
    var design_control_background_image: any = document.getElementById("design_control_background_image");
    var design_control_otp_screen: any = document.getElementById("design_control_otp_screen");
    var design_control_internal_pages: any = document.getElementById("design_control_internal_pages");
    var design_control_internal_pages_background_image: any = document.getElementById("design_control_internal_pages_background_image");


    if (design_control_upload_splash && design_control_upload_splash.value) {
      var files: any = document.getElementById("design_control_upload_splash");
      var file = files.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
                design_control_upload_splash = dd.body.imageUrl;
                
                var form_data = {
                  sessionid:this.store.get_token(),
                  column_value:design_control_upload_splash,
                  column_name:'design_control_upload_splash',
                  lusername:this.sub.Lusername.S,
                  xlusername:this.sub.XLusername.S
                };

                try {
                  this.api
                    .postmethod("customize/update_desgin_controls_images", form_data)
                    .subscribe((res: any) => {
                      console.log('done');
                    });
                } catch (err) {
                  this.api.handle_Error(err);
                }
          }
        });
    }

    if (design_control_background_image && design_control_background_image.value) {
      var files: any = document.getElementById("design_control_background_image");
      var file = files.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            design_control_background_image = dd.body.imageUrl;

            var form_data = {
              sessionid:this.store.get_token(),
              column_value:design_control_background_image,
              column_name:'design_control_background_image',
              lusername:this.sub.Lusername.S,
              xlusername:this.sub.XLusername.S
            };

            try {
              this.api
                .postmethod("customize/update_desgin_controls_images", form_data)
                .subscribe((res: any) => {
                  console.log('done');
                });
            } catch (err) {
              this.api.handle_Error(err);
            }

          }
        });
    }

    if (design_control_otp_screen && design_control_otp_screen.value) {
      var files: any = document.getElementById("design_control_otp_screen");
      var file = files.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            design_control_otp_screen = dd.body.imageUrl;

            var form_data = {
              sessionid:this.store.get_token(),
              column_value:design_control_otp_screen,
              column_name:'design_control_otp_screen',
              lusername:this.sub.Lusername.S,
              xlusername:this.sub.XLusername.S
            };

            try {
              this.api
                .postmethod("customize/update_desgin_controls_images", form_data)
                .subscribe((res: any) => {
                  console.log('done');
                });
            } catch (err) {
              this.api.handle_Error(err);
            }

          }
        });
    }

    if (design_control_internal_pages && design_control_internal_pages.value) {
      var files: any = document.getElementById("design_control_internal_pages");
      var file = files.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            design_control_internal_pages = dd.body.imageUrl;

            var form_data = {
              sessionid:this.store.get_token(),
              column_value:design_control_internal_pages,
              column_name:'design_control_internal_pages',
              lusername:this.sub.Lusername.S,
              xlusername:this.sub.XLusername.S
            };

            try {
              this.api
                .postmethod("customize/update_desgin_controls_images", form_data)
                .subscribe((res: any) => {
                  console.log('done');
                });
            } catch (err) {
              this.api.handle_Error(err);
            }

          }
        });
    }

    if (design_control_internal_pages_background_image && design_control_internal_pages_background_image.value) {
      var files: any = document.getElementById("design_control_internal_pages_background_image");
      var file = files.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            design_control_internal_pages_background_image = dd.body.imageUrl;

            var form_data = {
              sessionid:this.store.get_token(),
              column_value:design_control_internal_pages_background_image,
              column_name:'design_control_internal_pages_background_image',
              lusername:this.sub.Lusername.S,
              xlusername:this.sub.XLusername.S
            };

            try {
              this.api
                .postmethod("customize/update_desgin_controls_images", form_data)
                .subscribe((res: any) => {
                  console.log('done');
                });
            } catch (err) {
              this.api.handle_Error(err);
            }
          }
        });
    }

    // debugger
    var form_data = {
      sessionid:this.store.get_token(),
      color_combination:this.desginControlForm.value.color_combination,
      color_combination_two:this.desginControlForm.value.color_combination_two,
      select_theme:this.desginControlForm.value.select_theme,
      lusername:this.sub.Lusername.S,
      xlusername:this.sub.XLusername.S
    };

    try {
      this.api
        .postmethod("customize/desgin_controls", form_data)
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            if(popup){
              this.api.popover(res.message);
            }
            //this.featureControlForm.reset();
            this.getApps(this.update_Lusername)
          } else {
            //this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }

  get ios() {
    return this.iOForm.controls;
  }

  iOControl(popup) {
    this.submittediOS = true;
    
    if (this.iOForm.invalid && this.submittediOS) {
      return;
    }

    this.submittediOS = false;

    var ios_app_icon: any = document.getElementById("ios_app_icon");
    if (ios_app_icon && ios_app_icon.value) {
      var files: any = document.getElementById("ios_app_icon");
      var file = files.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            ios_app_icon = dd.body.imageUrl;
            var form_data = {
              sessionid:this.store.get_token(),
              column_value:ios_app_icon,
              column_name:'ios_app_icon',
              lusername:this.sub.Lusername.S,
              xlusername:this.sub.XLusername.S
            };
            try {
              this.api
                .postmethod("customize/update_ios_app_images", form_data)
                .subscribe((res: any) => {
                  console.log('done');
                });
            } catch (err) {
              //this.api.handle_Error(err);
            }

          }
        });
    }


    var ios_screenshot_iphone51: any = document.getElementById("ios_screenshot_iphone51");
    if (ios_screenshot_iphone51 && ios_screenshot_iphone51.value) {
      var file = ios_screenshot_iphone51.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            ios_screenshot_iphone51 = dd.body.imageUrl;

            var form_data = {
              sessionid:this.store.get_token(),
              column_value:ios_screenshot_iphone51,
              column_name:'ios_screenshot_iphone51',
              lusername:this.sub.Lusername.S,
              xlusername:this.sub.XLusername.S
            };
            try {
              this.api
                .postmethod("customize/ios_app_images_screenshot", form_data)
                .subscribe((res: any) => {
                  console.log('done');
                });
            } catch (err) {
              //this.api.handle_Error(err);
            }

          }
        });
    } 

    var ios_screenshot_iphone52: any = document.getElementById("ios_screenshot_iphone52");
    if (ios_screenshot_iphone52 && ios_screenshot_iphone52.value) {
      var file = ios_screenshot_iphone52.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            ios_screenshot_iphone52 = dd.body.imageUrl;

            var form_data = {
              sessionid:this.store.get_token(),
              column_value:ios_screenshot_iphone52,
              column_name:'ios_screenshot_iphone52',
              lusername:this.sub.Lusername.S,
              xlusername:this.sub.XLusername.S
            };
            try {
              this.api
                .postmethod("customize/ios_app_images_screenshot", form_data)
                .subscribe((res: any) => {
                  console.log('done');
                });
            } catch (err) {
              //this.api.handle_Error(err);
            }

          }
        });
    } 

    var ios_screenshot_iphone53: any = document.getElementById("ios_screenshot_iphone53");
    if (ios_screenshot_iphone53 && ios_screenshot_iphone53.value) {
      var file = ios_screenshot_iphone53.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            ios_screenshot_iphone53 = dd.body.imageUrl;

            var form_data = {
              sessionid:this.store.get_token(),
              column_value:ios_screenshot_iphone53,
              column_name:'ios_screenshot_iphone53',
              lusername:this.sub.Lusername.S,
              xlusername:this.sub.XLusername.S
            };
            try {
              this.api
                .postmethod("customize/ios_app_images_screenshot", form_data)
                .subscribe((res: any) => {
                  console.log('done');
                });
            } catch (err) {
              //this.api.handle_Error(err);
            }

          }
        });
    } 

    var ios_screenshot_iphone61: any = document.getElementById("ios_screenshot_iphone61");
    if (ios_screenshot_iphone61 && ios_screenshot_iphone61.value) {
      var file = ios_screenshot_iphone61.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            ios_screenshot_iphone61 = dd.body.imageUrl;

            var form_data = {
              sessionid:this.store.get_token(),
              column_value:ios_screenshot_iphone61,
              column_name:'ios_screenshot_iphone61',
              lusername:this.sub.Lusername.S,
              xlusername:this.sub.XLusername.S
            };
            try {
              this.api
                .postmethod("customize/ios_app_images_screenshot", form_data)
                .subscribe((res: any) => {
                  console.log('done');
                });
            } catch (err) {
              //this.api.handle_Error(err);
            }

          }
        });
    } 

    var ios_screenshot_iphone62: any = document.getElementById("ios_screenshot_iphone62");
    if (ios_screenshot_iphone62 && ios_screenshot_iphone62.value) {
      var file = ios_screenshot_iphone62.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            ios_screenshot_iphone62 = dd.body.imageUrl;

            var form_data = {
              sessionid:this.store.get_token(),
              column_value:ios_screenshot_iphone62,
              column_name:'ios_screenshot_iphone62',
              lusername:this.sub.Lusername.S,
              xlusername:this.sub.XLusername.S
            };
            try {
              this.api
                .postmethod("customize/ios_app_images_screenshot", form_data)
                .subscribe((res: any) => {
                  console.log('done');
                });
            } catch (err) {
              //this.api.handle_Error(err);
            }

          }
        });
    } 

    var ios_screenshot_iphone63: any = document.getElementById("ios_screenshot_iphone63");
    if (ios_screenshot_iphone63 && ios_screenshot_iphone63.value) {
      var file = ios_screenshot_iphone63.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            ios_screenshot_iphone63 = dd.body.imageUrl;

            var form_data = {
              sessionid:this.store.get_token(),
              column_value:ios_screenshot_iphone63,
              column_name:'ios_screenshot_iphone63',
              lusername:this.sub.Lusername.S,
              xlusername:this.sub.XLusername.S
            };
            try {
              this.api
                .postmethod("customize/ios_app_images_screenshot", form_data)
                .subscribe((res: any) => {
                  console.log('done');
                });
            } catch (err) {
              //this.api.handle_Error(err);
            }

          }
        });
    } 

    var ios_screenshot_ipad1: any = document.getElementById("ios_screenshot_ipad1");
    if (ios_screenshot_ipad1 && ios_screenshot_ipad1.value) {
      var file = ios_screenshot_ipad1.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            ios_screenshot_ipad1 = dd.body.imageUrl;

            var form_data = {
              sessionid:this.store.get_token(),
              column_value:ios_screenshot_ipad1,
              column_name:'ios_screenshot_ipad1',
              lusername:this.sub.Lusername.S,
              xlusername:this.sub.XLusername.S
            };
            try {
              this.api
                .postmethod("customize/ios_app_images_screenshot", form_data)
                .subscribe((res: any) => {
                  console.log('done');
                });
            } catch (err) {
              //this.api.handle_Error(err);
            }

          }
        });
    } 

    var ios_screenshot_ipad2: any = document.getElementById("ios_screenshot_ipad2");
    if (ios_screenshot_ipad2 && ios_screenshot_ipad2.value) {
      var file = ios_screenshot_ipad2.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            ios_screenshot_ipad2 = dd.body.imageUrl;

            var form_data = {
              sessionid:this.store.get_token(),
              column_value:ios_screenshot_ipad2,
              column_name:'ios_screenshot_ipad2',
              lusername:this.sub.Lusername.S,
              xlusername:this.sub.XLusername.S
            };
            try {
              this.api
                .postmethod("customize/ios_app_images_screenshot", form_data)
                .subscribe((res: any) => {
                  console.log('done');
                });
            } catch (err) {
              //this.api.handle_Error(err);
            }

          }
        });
    } 

    var ios_screenshot_ipad3: any = document.getElementById("ios_screenshot_ipad3");
    if (ios_screenshot_ipad3 && ios_screenshot_ipad3.value) {
      var file = ios_screenshot_ipad3.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            ios_screenshot_ipad3 = dd.body.imageUrl;

            var form_data = {
              sessionid:this.store.get_token(),
              column_value:ios_screenshot_ipad3,
              column_name:'ios_screenshot_ipad3',
              lusername:this.sub.Lusername.S,
              xlusername:this.sub.XLusername.S
            };
            try {
              this.api
                .postmethod("customize/ios_app_images_screenshot", form_data)
                .subscribe((res: any) => {
                  console.log('done');
                });
            } catch (err) {
              //this.api.handle_Error(err);
            }

          }
        });
    } 

    // debugger
    var form_data = {
      sessionid:this.store.get_token(),
      lusername:this.sub.Lusername.S,
      xlusername:this.sub.XLusername.S,
      ios_app_name:this.iOForm.value.ios_app_name,
      ios_app_subtitle:this.iOForm.value.ios_app_subtitle,
      ios_app_submission_category:this.iOForm.value.ios_app_submission_category,
      ios_app_submission_sub_category:this.iOForm.value.ios_app_submission_sub_category,
      ios_default_language:this.iOForm.value.ios_default_language,
      ios_country_availability:this.iOForm.value.ios_country_availability,
      ios_privacy_policy_page_link:this.iOForm.value.ios_privacy_policy_page_link,
      ios_app_keywords:this.iOForm.value.ios_app_keywords,
      ios_app_description:this.iOForm.value.ios_app_description,
      ios_app_support_url:this.iOForm.value.ios_app_support_url,
      ios_app_marketing_url:this.iOForm.value.ios_app_marketing_url,
      ios_promotion_video_url:this.iOForm.value.ios_promotion_video_url,
      ios_app_version:this.iOForm.value.ios_app_version,
      ios_app_rating:this.iOForm.value.ios_app_rating,
      ios_price:this.iOForm.value.ios_price
    };

    try {
      this.api
        .postmethod("customize/ios_app", form_data)
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            if(popup){
              this.api.popover(res.message);
            }
            //this.iOForm.reset();
            setTimeout(()=>{
              this.getApps(this.update_Lusername)
            }, 4000)
          } else {
            //this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }

  get android() {
    return this.androidForm.controls;
  }

  androidControl(popup) {
    this.submittedAndroid = true;
    
    if (this.androidForm.invalid && this.submittedAndroid) {
      return;
    }

    this.submittedAndroid = false;

    var android_app_icon: any = document.getElementById("android_app_icon");
    if (android_app_icon && android_app_icon.value) {
     
      var files: any = document.getElementById("android_app_icon");
      var file = files.files[0];
      
      if (file == null) {
        return alert("No file selected.");
      }

      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            android_app_icon = dd.body.imageUrl;

            var form_data = {
              sessionid:this.store.get_token(),
              column_value:android_app_icon,
              column_name:'android_app_icon',
              lusername:this.sub.Lusername.S,
              xlusername:this.sub.XLusername.S
            };

            try {
              this.api
                .postmethod("customize/update_android_app_images", form_data)
                .subscribe((res: any) => {
                  console.log('done');
                });
            } catch (err) {
              //this.api.handle_Error(err);
            }

          }
        });
    }

    var android_screenshot_phone1: any = document.getElementById("android_screenshot_phone1");
    if (android_screenshot_phone1 && android_screenshot_phone1.value) {
      var file = android_screenshot_phone1.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            android_screenshot_phone1 = dd.body.imageUrl;
            var form_data = {
              sessionid:this.store.get_token(),
              column_value:android_screenshot_phone1,
              column_name:'android_screenshot_phone1',
              lusername:this.sub.Lusername.S,
              xlusername:this.sub.XLusername.S
            };
            try {
              this.api
                .postmethod("customize/android_app_images_screenshot", form_data)
                .subscribe((res: any) => {
                  console.log('done');
                });
            } catch (err) {
              //this.api.handle_Error(err);
            }
          }
        });
    } 

    var android_screenshot_phone2: any = document.getElementById("android_screenshot_phone2");
    if (android_screenshot_phone2 && android_screenshot_phone2.value) {
      var file = android_screenshot_phone2.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            android_screenshot_phone2 = dd.body.imageUrl;
            var form_data = {
              sessionid:this.store.get_token(),
              column_value:android_screenshot_phone2,
              column_name:'android_screenshot_phone2',
              lusername:this.sub.Lusername.S,
              xlusername:this.sub.XLusername.S
            };
            try {
              this.api
                .postmethod("customize/android_app_images_screenshot", form_data)
                .subscribe((res: any) => {
                  console.log('done');
                });
            } catch (err) {
              //this.api.handle_Error(err);
            }
          }
        });
    }

    var android_screenshot_phone3: any = document.getElementById("android_screenshot_phone3");
    if (android_screenshot_phone3 && android_screenshot_phone3.value) {
      var file = android_screenshot_phone3.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            android_screenshot_phone3 = dd.body.imageUrl;
            var form_data = {
              sessionid:this.store.get_token(),
              column_value:android_screenshot_phone3,
              column_name:'android_screenshot_phone3',
              lusername:this.sub.Lusername.S,
              xlusername:this.sub.XLusername.S
            };
            try {
              this.api
                .postmethod("customize/android_app_images_screenshot", form_data)
                .subscribe((res: any) => {
                  console.log('done');
                });
            } catch (err) {
              //this.api.handle_Error(err);
            }
          }
        });
    }

    var android_screenshot_tablet1: any = document.getElementById("android_screenshot_tablet1");
    if (android_screenshot_tablet1 && android_screenshot_tablet1.value) {
      var file = android_screenshot_tablet1.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            android_screenshot_tablet1 = dd.body.imageUrl;
            var form_data = {
              sessionid:this.store.get_token(),
              column_value:android_screenshot_tablet1,
              column_name:'android_screenshot_tablet1',
              lusername:this.sub.Lusername.S,
              xlusername:this.sub.XLusername.S
            };
            try {
              this.api
                .postmethod("customize/android_app_images_screenshot", form_data)
                .subscribe((res: any) => {
                  console.log('done');
                });
            } catch (err) {
              //this.api.handle_Error(err);
            }
          }
        });
    }

    var android_screenshot_tablet2: any = document.getElementById("android_screenshot_tablet2");
    if (android_screenshot_tablet2 && android_screenshot_tablet2.value) {
      var file = android_screenshot_tablet2.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            android_screenshot_tablet2 = dd.body.imageUrl;
            var form_data = {
              sessionid:this.store.get_token(),
              column_value:android_screenshot_tablet2,
              column_name:'android_screenshot_tablet2',
              lusername:this.sub.Lusername.S,
              xlusername:this.sub.XLusername.S
            };
            try {
              this.api
                .postmethod("customize/android_app_images_screenshot", form_data)
                .subscribe((res: any) => {
                  console.log('done');
                });
            } catch (err) {
              //this.api.handle_Error(err);
            }
          }
        });
    }

    var android_screenshot_tablet3: any = document.getElementById("android_screenshot_tablet3");
    if (android_screenshot_tablet3 && android_screenshot_tablet3.value) {
      var file = android_screenshot_tablet3.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            android_screenshot_tablet3 = dd.body.imageUrl;
            var form_data = {
              sessionid:this.store.get_token(),
              column_value:android_screenshot_tablet3,
              column_name:'android_screenshot_tablet3',
              lusername:this.sub.Lusername.S,
              xlusername:this.sub.XLusername.S
            };
            try {
              this.api
                .postmethod("customize/android_app_images_screenshot", form_data)
                .subscribe((res: any) => {
                  console.log('done');
                });
            } catch (err) {
              //this.api.handle_Error(err);
            }
          }
        });
    }

    var android_screenshot_featuredimage: any = document.getElementById("android_screenshot_featuredimage");
    if (android_screenshot_featuredimage && android_screenshot_featuredimage.value) {
      var file = android_screenshot_featuredimage.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            android_screenshot_featuredimage = dd.body.imageUrl;
            var form_data = {
              sessionid:this.store.get_token(),
              column_value:android_screenshot_featuredimage,
              column_name:'android_screenshot_featuredimage',
              lusername:this.sub.Lusername.S,
              xlusername:this.sub.XLusername.S
            };
            try {
              this.api
                .postmethod("customize/android_app_images_screenshot", form_data)
                .subscribe((res: any) => {
                  console.log('done');
                });
            } catch (err) {
              //this.api.handle_Error(err);
            }
          }
        });
    }

    // debugger
    var form_data = {
      sessionid:this.store.get_token(),
      lusername:this.sub.Lusername.S,
      xlusername:this.sub.XLusername.S,
      android_app_name:this.androidForm.value.android_app_name,
      android_app_subtitle:this.androidForm.value.android_app_subtitle,
      android_app_submission_category:this.androidForm.value.android_app_submission_category,
      android_app_submission_sub_category:this.androidForm.value.android_app_submission_sub_category,
      android_default_language:this.androidForm.value.android_default_language,
      android_country_availability:this.androidForm.value.android_country_availability,
      android_privacy_policy_page_link:this.androidForm.value.android_privacy_policy_page_link,
      android_app_keywords:this.androidForm.value.android_app_keywords,
      android_app_description:this.androidForm.value.android_app_description,
      android_app_support_url:this.androidForm.value.android_app_support_url,
      android_app_marketing_url:this.androidForm.value.android_app_marketing_url,
      android_promotion_video_url:this.androidForm.value.android_promotion_video_url,
      android_app_version:this.androidForm.value.android_app_version,
      android_app_rating:this.androidForm.value.android_app_rating,
      android_price:this.androidForm.value.android_price
    };

    try {
      this.api
        .postmethod("customize/android_app", form_data)
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            if(popup){
              this.api.popover(res.message);
            }
            //this.androidForm.reset();
            setTimeout(()=>{
              this.getApps(this.update_Lusername)
            }, 4000)
          } else {
            //this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
}
