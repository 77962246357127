import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";
import { MatDialog } from "@angular/material/dialog";
import {
  ConfirmDialogModel,
  ConfirmDialogComponent
} from "../../confirm-dialog/confirm-dialog.component";


@Component({
  selector: 'app-list-emails',
  templateUrl: './list-emails.component.html',
  styleUrls: ['./list-emails.component.css']
})
export class ListEmailsComponent implements OnInit {
  constructor(
    private router: Router,
    private api: ApiService,
    private store: StorageService,
    public dialog: MatDialog
  ) {}

  developers: any = [];
  lecturertemplates: any = [];
  developertemplates: any = [];
  stafftemplates: any = [];
  ngOnInit() {
    this.lecturertemplate('lecturer');
    this.developertemplate('developer');
    this.stafftemplate('staff');
  }
  developerlist() {
    try {
      this.api
        .postmethod("user/all/developer", {
          sessionid: this.store.get_token()
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.developers = res.data.Items;
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  
  lecturertemplate(template_type) {
    try {
      this.api
        .postmethod("user/email_get/listlecturer", {
          sessionid: this.store.get_token(),
          template_type: template_type,
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.lecturertemplates = res.data.Items
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  developertemplate(template_type) {
    try {
      this.api
        .postmethod("user/email_get/listlecturer", {
          sessionid: this.store.get_token(),
          template_type: template_type,
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.developertemplates = res.data.Items
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  stafftemplate(template_type) {
    try {
      this.api
        .postmethod("user/email_get/listlecturer", {
          sessionid: this.store.get_token(),
          template_type: template_type,
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.stafftemplates = res.data.Items
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  accessLabel() {
    this.router.navigate(["/accesslabel"]);
  }

  appView() {
    this.router.navigate(["/listapps"]);
  }

  goBack() {
    window.history.back();
  }
  
  addNewDeveloper() {
    this.router.navigate(["/developeraccounts"]);
  }
    changeDeveloperPassword() {
    this.router.navigate(["/changedeveloperpassword"]);
  }
  
  edittemplate(data, data1){
    this.router.navigate(["editemails"], {
      queryParams: {
        data: JSON.stringify({template_type:data, template_name: data1})
      }
    });
  }
  edittemplatedeveloper(data, data1){
    this.router.navigate(["editemailsdeveloper"], {
      queryParams: {
        data: JSON.stringify({template_type:data, template_name: data1})
      }
    });
  }
  edittemplatestaff(data, data1){
    this.router.navigate(["editemailsstaff"], {
      queryParams: {
        data: JSON.stringify({template_type:data, template_name: data1})
      }
    });
  }
  emailListEdit(data) {
    this.router.navigate(["editemails"], {
      queryParams: {
        data: JSON.stringify(data)
      }
    });
  }
  deletedeveloper(data: any, data1: any) {
    try {
      this.api
        .postmethod("user/delete/developer", {
          sessionid: this.store.get_token(),
          lusername: data,
          xlusername: data1
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            this.developerlist();
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }

  result: string = "";
  confirmDialog(data, data1): void {
    var message = `Are you sure you want to delete?`;

    var dialogData = new ConfirmDialogModel("Confirm Action", message);

    var dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;

      if (this.result) {
        this.deletedeveloper(data, data1);
      }
    });
  }
}
