import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";

@Component({
  selector: "app-acc-maga",
  templateUrl: "./acc-maga.component.html",
  styleUrls: ["./acc-maga.component.css"]
})
export class AccMagaComponent implements OnInit {
  submitted = false;
  accForm: FormGroup;
  constructor(
    private api: ApiService,
    private store: StorageService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.accForm = this.formBuilder.group({
      appname: ["", [Validators.required]],
      description: ["", [Validators.required]]
    });
  }

  get f() {
    return this.accForm.controls;
  }
  resetPreview() {
    this.router.navigate(["/listapps"]);
  }
  goBack() {
    window.history.back();
  }
  submitPreview() {
    this.submitted = true;
    if (this.accForm.invalid) {
      return;
    }
//   debugger;
    try {
      this.api
        .postmethod("user/create/application", {
          sessionid: this.store.get_token(),
          appname: this.accForm.value.appname,
          description: this.accForm.value.description,
          template: "1",
          shortdesc: this.accForm.value.description,
          longdesc: this.accForm.value.description,
          iosimage: this.accForm.value.description,
          ioscategory: this.accForm.value.description,
          iosicon: this.accForm.value.description,
          iossplash: this.accForm.value.description,
          androidimage: this.accForm.value.description,
          androidcategory: this.accForm.value.description,
          androidicon: this.accForm.value.description,
          androidsplash: this.accForm.value.description,
          lecturers: "",
          emailnotification: true,
          developer_push: true,
          timestamp: Date.now(),
          enablestatus: true
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            this.router.navigate(["/listapps"]);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
}
